import { cloneDeep } from 'lodash'
import * as React from 'react'
import ConfirmAlert from 'sweetalert2'

import Button from 'shared/Button'
import Documents from 'shared/DocumentZone/Documents'
import ActionButtons from 'shared/DocumentZone/Documents/ActionButtons'
import DropZone from 'shared/DropZone'
import LoadingIndicator from 'shared/LoadingIndicator'
import PDFViewer from 'shared/PDFViewer'

import { ActionEnum } from 'shared/DocumentZone/Documents/ActionButtons/Types'
import { AcceptsEnum } from 'shared/DropZone/Types'
import { MarkupModeEnum, MarkupSourceEnum } from 'shared/MarkupTool/Types'
import { PDFDocumentType } from 'shared/PDFViewer/Types'
import { PermissionEnum } from './Types'

import { getLoggedInUser, Strings } from 'utils'

import {
  Col,
  Container,
  Header,
  PDFContainer,
  Row,
  Text,
  Title,
} from './Styled'

import { faEllipsisVAlt, faList, faTh, faTimes } from '@fortawesome/pro-light-svg-icons'
import { faChevronLeft, faChevronRight, faList as fasList, faTh as fasTh } from '@fortawesome/pro-solid-svg-icons'
import AddIconToLibrary from 'utils/FontAwesomeIcon'
AddIconToLibrary([faChevronLeft, faChevronRight, faEllipsisVAlt, faList, fasList, fasTh, faTh, faTimes])

interface Props {
  allowMultiple: boolean
  disableClick: boolean
  docs: PDFDocumentType[]
  gridActions: string[]
  listActions: string[]
  loadingTrigger: string
  onAction: (action: ActionEnum, data: any) => void
  onUploadEach: (file: File) => void
  resizeTrigger: boolean
  viewActions: string[]
  disabled?: boolean
}

interface State {
  activeIndex: number
  loading: boolean
  loadingMessage: string
  markupMode: MarkupModeEnum
  originalDocument: PDFDocumentType,
  permission: PermissionEnum
  showEllipsisOptions: boolean
  viewFormat: string
  zoom: number
}

class DocumentZone extends React.Component<Props, State> {
  public static defaultProps = {
    allowMultiple: true,
    disableClick: false,
    docs: [] as PDFDocumentType[],
    gridActions: ['trash'],
    listActions: ['trash'],
    loadingTrigger: '',
    onAction: (action: string, data: any) => { return },
    resizeTrigger: false,
    viewActions: ['trash'],
  }

  public state = {
    activeIndex: -1,
    loading: false,
    loadingMessage: '',
    markupMode: MarkupModeEnum.None,
    originalDocument: {} as PDFDocumentType,
    permission: PermissionEnum.None,
    showEllipsisOptions: false,
    viewFormat: 'grid',
    zoom: 1,
  }

  public render = () => {
    const {
      allowMultiple,
      disableClick,
      docs,
      gridActions,
      listActions,
      loadingTrigger,
      resizeTrigger,
      viewActions,
      disabled
    } = this.props

    const {
      activeIndex,
      loading,
      loadingMessage,
      markupMode,
      permission,
      viewFormat,
      zoom,
    } = this.state

    const isMarkupEditor = markupMode >= MarkupModeEnum.Editor

    return (
      <Container className='rp-document-zone'>
        {(loadingTrigger || loading) && <LoadingIndicator message={loadingTrigger || loadingMessage} />}
        {activeIndex < 0 ? (
          <Header justify="space-between" padding={1}>
            <Text>Drop PDF Documents in the area below.</Text>
            <Row>
              <Button.Icon
                className='layout'
                isActive={viewFormat === 'grid'}
                onClick={() => this.handleLayout('grid')}
                label={'Grid View'}
                icon={viewFormat === 'grid' ? fasTh : faTh}
              />
              <Button.Icon
                className='layout'
                isActive={viewFormat === 'list'}
                onClick={() => this.handleLayout('list')}
                label={'List View'}
                icon={viewFormat === 'list' ? fasList : faList}
              />
            </Row>
          </Header>
        ) : (
          <Header justify="space-between" padding={'0.5em 1em'}>
            {isMarkupEditor ? (
              <Text>Drag across an area of the document to add notes, signatures or text.</Text>
            ): (
              <React.Fragment>
                <Row spacing={0.25}>
                  <Button.Icon
                    icon={faTimes}
                    label={'Close Preview'}
                    onClick={this.deactivateDocument}
                  />
                  {docs.length > 1 && (
                    <React.Fragment>
                      <Button.Bubble
                        className='previous'
                        icon={faChevronLeft}
                        onClick={() => this.handleNav(-1)}
                        label='Previous Document'
                      />
                      <Button.Bubble
                        className='next'
                        icon={faChevronRight}
                        onClick={() => this.handleNav(1)}
                        label='Next Document'
                      />
                    </React.Fragment>
                  )}
                </Row>
                <Title>{docs[activeIndex] && docs[activeIndex].fileName}</Title>
              </React.Fragment>
            )}
            <Row>
              <ActionButtons
                actions={isMarkupEditor ? ['confirm', 'cancel'] : viewActions}
                doc={docs[activeIndex]}
                onAction={this.handleAction}
                permission={permission}
              />
            </Row>
          </Header>
        )}
        <DropZone
          accepts={AcceptsEnum.Pdf}
          allowMultiple={allowMultiple}
          disableClick={disableClick || activeIndex >= 0}
          onDropEach={this.handleDropEach}
          showBorder={activeIndex < 0}
          showImage={true}
          stagger={true}
          disabled={disabled}
        >
          {activeIndex < 0 ? (
            <Col>
              <Documents
                docs={docs}
                gridActions={gridActions}
                listActions={listActions}
                onAction={this.handleAction}
                getPermission={this.getPermission}
                viewFormat={viewFormat}
              />
            </Col>
          ) : (
            <PDFContainer>
              {permission !== PermissionEnum.None ? (
                <PDFViewer
                  doc={docs[activeIndex]}
                  resize={resizeTrigger}
                  zoom={zoom}
                  markupMode={markupMode}
                  markupSource={MarkupSourceEnum.User}
                  onUpdate={this.handleUpdateDocuments}
                />
              ) : (
                <Text>You do not have permission to view this document.</Text>
              )}
            </PDFContainer>
          )}
        </DropZone>
      </Container>
    )
  }

  private activateDocument = async (activeIndex: number) => {
    const { docs, onAction } = this.props
    this.setState({ activeIndex: -1 })

    if (docs[activeIndex]) {
      const user: any = await getLoggedInUser({ fromCache: true })
      if (!user) {
        return
      }
      const permission = await this.getPermission(docs[activeIndex])
      window.requestAnimationFrame(() => {
        this.setState({
          activeIndex,
          markupMode: permission <= PermissionEnum.Viewer ? MarkupModeEnum.Viewer : MarkupModeEnum.None,
          permission,
          zoom: 1
        })
        onAction(ActionEnum.View, docs[activeIndex])
      })
    }
  }

  private deactivateDocument = () => {
    const { onAction } = this.props
    this.setState({ activeIndex: -1 })
    return onAction(ActionEnum.Close, null)
  }

  private handleNav = (direction: number) => {
    const { docs } = this.props
    const { activeIndex } = this.state

    let index = activeIndex + direction
    index = index < 0 ? docs.length - 1 : index
    index = index > docs.length - 1 ? 0 : index

    this.activateDocument(index)
  }

  private handleLayout = (viewFormat: string) => {
    this.setState({ viewFormat })
  }

  private handleDropEach = async (file: File, index: number, total: number) => {
    const { onUploadEach } = this.props
    if (index === 1) {
      this.setState({ loading: true })
    }
    this.setState({ loadingMessage: `${index}/${total} Uploading document ${file.name}...` })
    await onUploadEach(file)
    if (index === total) {
      this.setState({ loading: false })
    }
  }

  private handleAction = (action: ActionEnum, data: any) => {
    const { onAction } = this.props
    const { markupMode } = this.state

    switch (action) {
      case ActionEnum.View:
        this.handleViewDocument(data)
        break

      case ActionEnum.ZoomIn:
        this.handleZoom(1)
        break

      case ActionEnum.ZoomOut:
        this.handleZoom(-1)
        break

      case ActionEnum.Trash:
        this.handleDelete(action, data)
        break

      case ActionEnum.Markup:
        this.toggleMarkup()
        break

      case ActionEnum.Confirm:
        this.handleSaveOne()
        if (markupMode >= MarkupModeEnum.Editor) {
          this.toggleMarkup()
        }
        break

      case ActionEnum.Cancel:
        this.handleCancelChanges()
        if (markupMode >= MarkupModeEnum.Editor) {
          this.toggleMarkup()
        }
        break

      default:
        return onAction(action, data)
    }
  }

  private handleZoom = (direction: number) => {
    const { zoom } = this.state
    if ((direction > 0 && zoom < 4) || (direction < 0 && zoom > 1)) {
      const increment = 0.2 * direction
      this.setState({
        zoom: zoom + increment
      })
    }
  }

  private handleViewDocument = (doc: PDFDocumentType) => {
    const { docs } = this.props

    const index = docs.findIndex((item: PDFDocumentType) => item._id === doc._id)
    if (index < 0) {
      return
    }
    this.activateDocument(index)
  }

  private handleUpdateDocuments = (doc: PDFDocumentType) => {
    const { docs, onAction } = this.props
    const { activeIndex } = this.state

    const update = cloneDeep(docs)
    update[activeIndex] = doc
    onAction(ActionEnum.UpdateAll, update)
  }

  private handleSaveOne = () => {
    const { docs, onAction } = this.props
    const { activeIndex } = this.state

    onAction(ActionEnum.SaveOne, docs[activeIndex])
  }

  private handleCancelChanges = () => {
    const { docs, onAction } = this.props
    const { originalDocument, activeIndex } = this.state

    const update = cloneDeep(docs)
    update[activeIndex] = { ...originalDocument }
    onAction(ActionEnum.UpdateAll, update)
  }

  private handleDelete = async (action: ActionEnum, data: any) => {
    const { docs, onAction } = this.props
    const { activeIndex } = this.state

    const confirm = await ConfirmAlert({
      cancelButtonText: Strings.kanbanView.noKeepIt,
      confirmButtonText: Strings.kanbanView.yesDeleteIt,
      showCancelButton: true,
      text: Strings.transactionDocumentTab.documentList.document.recoverDocument,
      title: Strings.kanbanView.sure,
      type: 'warning'
    })

    if (confirm.value) {
      if (docs.length === 1) {
        this.setState({ activeIndex: -1 })
      } else if (activeIndex !== -1) {
        this.handleNav(-1)
      }
      onAction(action, data)
    }
  }

  private toggleMarkup = async () => {
    const { docs } = this.props
    const { activeIndex, markupMode } = this.state

    const originalDocument = (activeIndex > -1) ? cloneDeep(docs[activeIndex]) : {} as PDFDocumentType
    let mode = markupMode < MarkupModeEnum.Editor ? MarkupModeEnum.Editor : MarkupModeEnum.Viewer

    const permission = await this.getPermission(originalDocument)
    if (permission > PermissionEnum.Viewer) {
      mode = MarkupModeEnum.None
    }

    this.setState({
      originalDocument,
      markupMode: mode
    })
  }

  private getPermission = async (doc: PDFDocumentType) => {
    if (!doc._id) {
      return PermissionEnum.None
    }

    const { isLocked, owner, transaction } = doc

    const user: any = await getLoggedInUser({ fromCache: true })
    const admin: any = transaction ? transaction.owner : null

    if (!user || !owner) {
      return PermissionEnum.None
    }

    if (admin && user._id === admin._id) {
      return PermissionEnum.Owner
    }

    if (user._id === owner._id || user.role === 'ADMIN' || user.role === 'MANAGER') {
      return PermissionEnum.Owner
    }

    if (isLocked) {
      return PermissionEnum.None
    }

    return PermissionEnum.Viewer
  }
}

export default DocumentZone
