/* eslint:disable:quotes */
const Strings = {
  activityDuration: {
    day: '1 DAY AGO',
    month: '1 MONTH AGO',
    today: 'TODAY',
    week: '1 WEEK AGO'
  },
  addCard: {
    add: 'Add',
    cancel: 'X',
    placeholder: 'Add a Card'
  },
  addColumn: {
    cancel: 'Cancel',
    name: 'cardTitle',
    placeholder: 'Name your list',
    preview: 'Add a Column...',
    save: 'Save'
  },
  addWidget: {
    firstLine: '+ Add',
    secondLine: 'Widget'
  },
  advanceSearchModal: {
    filters: 'FILTERS'
  },
  billing: {
    alert: {
      downgrade: {
        cancel: 'No, keep current plan',
        confirm: 'Yes, cancel current plan',
        description:
          'Doing so will disable your ability to create new Transactions and Offices. It may also disable your use of certain Premium Passes and features.',
        title: 'Cancel your current plan?'
      }
    },
    button: {
      viewHistory: 'View History'
    },
    description: {
      footnote1:
        'Recurring items are charged at the beginning of each billing cycle and invoice totals may reflect a balance forward.'
    },
    title: {
      details: 'Billing Details',
      nextInvoice: 'Next Invoice',
      total: 'Total'
    }
  },
  buttonText: {
    addTaskBtn: 'ADD TASK',
    companyBtn: 'Company',
    nationalBtn: 'National',
    saveTaskBtn: 'SAVE TASK',
    upgradeBtn: 'Upgrade'
  },
  checklistManager: {
    alert: {
      deleteTemplate: {
        cancel: 'No, keep it',
        confirm: 'Yes, delete it',
        error: 'An error occurred while attempting to delete the item.',
        success: 'The checklist item has been deleted.',
        title: 'Delete this Checklist item?'
      }
    },
    subtitle: {
      main: 'Contrary to popular belief'
    },
    title: {
      main: 'Checklist Manager'
    }
  },
  contact: {
    Action: 'Action',
    AddedOn: 'Created Date',
    ContactName: 'Contact Name',
    ContactNo: 'Contact No.',
    Download: 'Download',
    Owner: 'Owner',
    Source: 'Source',
    Type: 'Type',
    action: 'action',
    addContact: 'Add Contact',
    addSharedWith: '+ Shared With',
    addedOn: 'Added On : ',
    addedOns: 'createdAt',
    another: 'Import Another File',
    archive: 'Archive',
    avatar: `${require('design/icons/offices/user_0001_Group-2.png')}`,
    avatar1: `${require('design/icons/offices/user_0002_Group-3.png')}`,
    avatar2: `${require('design/icons/offices/user_0003_Group-4.png')}`,
    checkbox: 'checkbox',
    columnMetaData: {
      id: {
        addedDate: 'addedDate',
        listingId: 'listingId',
        modifiedDate: 'modifiedDate',
        ownerName: 'ownerName',
        price: 'price',
        propertySide: 'propertyside',
        sharedWith: 'sharedWith',
        status: 'status',
        transactionId: 'transactionId'
      },
      title: {
        addedDate: 'Added Date',
        listingId: 'Listing Id',
        modifiedDate: 'Modified Date',
        ownerName: 'Owner Name',
        price: 'Price',
        propertySide: 'Property Side',
        sharedWith: 'Shared With',
        status: 'Status',
        transactionId: 'Transaction Id'
      }
    },
    contactName: 'contactName',
    delete: 'Delete',
    duplicate: 'Duplicate processed:',
    fileFormat: 'File Format:',
    header: 'First row Is header:',
    image: `${require('design/images/share-with.png')}`,
    import: 'Import',
    importExapmle: 'Import file exapmle: ',
    notes: 'Notes',
    notesName: 'Notes Name',
    optional: '(Optional)',
    owner: 'Owner',
    owners: 'firstName',
    personal: 'Personal Information',
    records: 'Records',
    relatedTo: 'Related to : ',
    selectedFile: 'Selected file',
    seperator: 'Column Seperator',
    shareWith: 'Share With',
    sharedWith: 'Shared With',
    skip: 'Skip empty column:',
    source: 'Source : ',
    sources: 'source',
    src: `${require('design/icons/contacts/AddContact.png')}`,
    src1: `${require('design/icons/contacts/EditContact.png')}`,
    successfully: 'Successfully Imported Records:',
    text:
      'Lforem Ipsum is simply dummay text of the printing and typesetting industry',
    texts:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing',
    type: 'type',
    viewDetails: 'View Details'
  },
  dashboard: {
    MLS: 'MLS : ',
    addButton: {
      cancel: 'Cancel',
      save: 'Save'
    },
    addPass: 'ADD PASS',
    addSection: 'ADD NEW SECTION',
    addSectionPlaceholder: 'Name Your Section',
    colors: 'Panel Colors',
    commission: {
      addNotes: '+ Add Notes',
      capContribution: 'CAP Contribution',
      residentialCapContribution: 'Residential CAP Contribution',
      commercialCapContribution: 'Commercial CAP Contribution',
      closingOn: 'closing on',
      commDue: 'Total Due',
      commissionDisbursementForm: 'Commission Disbursement Form',
      commissionPlan: 'Commission Plan',
      commissionReceived: 'Commission Received',
      dateReceived: 'Date Received',
      expectedCommission: 'Expected Commission',
      feeBreakdown: 'Fee Breakdown',
      financialSummary: 'Financial Summary',
      generateCDF: 'Generate CDF',
      generating: 'Generating...',
      licenseExp: 'License Exp',
      loadingChart: 'Loading Chart',
      managerNotes: 'Manager Notes',
      mark: 'has a $100 Credit. Apply to transaction?',
      modify: 'Modify',
      notes: 'Manager Notes',
      paymentMethod: 'Payment Method',
      receivedOn: 'received on',
      referralFee: 'Referral Fee',
      totalCredits: 'Total Credits',
      totalDebits: 'Total Debits',
      totalExpenses: 'Total Expenses',
      totalIncome: 'Total Income',
      transaction: 'Summary',
      individualCapAdjustmentInfoError: 'CAP is met or exceeds the target CAP, please adjust the amount.',
      teamCapAdjustmentInfoError: 'Team CAP is met or exceeds the target CAP, please adjust the amount.'
    },
    create: 'Create',
    customize: 'Customize',
    delete: 'Delete',
    due: 'Due : ',
    duplicate: 'Duplicate',
    header: {
      pass: 'Manage Plan',
      planName: {
        firstPlane: 'Tier 1',
        fourthPlane: 'Tier 4',
        secondPlane: 'Tier 2',
        thirdPlane: 'Tier 3'
      },
      policy: 'Privacy Policy',
      progress: 'Usage',
      signOut: 'Sign Out'
    },
    loadMore: 'Load More',
    mockWidgetTitle: 'My Passes',
    ok: 'Ok',
    passes: {
      activities: 'Activities',
      configurePass: 'Configure Pass',
      contactsAdded: 'Contacts Added',
      count: 'Count',
      leadDays: 'Lead Summary',
      leadName: 'Lead Name',
      leadsGenerated: 'NEW Leads',
      listingId: 'Listing Id',
      myAssistant: 'Onboarding Assistant',
      new: 'New',
      pastDue: 'Past Due',
      payable: 'Payable',
      price: 'Price',
      propertyAddress: 'Property Address',
      propertySide: 'Property Side',
      quickTutorials: 'Quick Tutorials',
      received: 'Received',
      referralsAccepted: 'Referrals Accepted',
      score: 'Score',
      source: 'Source',
      tasks: 'Tasks',
      time: 'Select Time Period',
      transaction: 'Transactions Generated',
      transactionDays: 'Days Transaction Summary',
      transactionId: 'Transaction Id',
      transactionSummaryPass: 'Transaction Summary Pass',
      trend: 'Trend',
      type: 'Type',
      upcoming: 'Upcoming',
      updated: 'Updated'
    },
    recent: 'Recent',
    rename: 'Rename',
    searchPassModal: {
      add: 'ADD',
      dummyText:
        'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
      graph: 'GRAPHS',
      learnMore: 'Learn More',
      moreInfo: 'MORE INFO',
      text:
        'Lorem ipsum dolor sit amet, consec Lorem ipsum dolor sit amet, conses & non est.'
    },
    transactionStatus: 'Transaction Status',
    type: 'Type : ',
    wallpaper: 'Wallpaper',
    widgetButtons: {
      customize: 'Customize',
      delete: 'Delete',
      duplicate: 'Duplicate',
      rename: 'Rename',
      settings: 'Settings'
    }
  },
  eventsPopup: {
    calendarPanel: {
      addTask: 'Add Task',
      avatar: `${require('design/icons/dashboard/avatar.png')}`,
      eventEffect: `${require('design/icons/dashboard/effect.png')}`,
      eventShared: 'Event Shared With',
      eventTag: 'Tags',
      export: 'Export',
      googleImport: 'Google import',
      month: 'MONTH',
      nextMonth: `${require('design/icons/dashboard/next_month.png')}`,
      previousMonth: `${require('design/icons/dashboard/previous_month.png')}`,
      taskShared: 'Task Shared With',
      today: 'Today',
      week: 'WEEK'
    },
    leftPanel: {
      addTask: `${require('design/icons/dashboard/add-task.png')}`,
      googleCalendar: `${require('design/icons/dashboard/google-calendar.png')}`
    },
    rightPanel: {
      addEvent: `${require('design/icons/dashboard/add-task-timeline.png')}`,
      eventDelete: `${require('design/icons/dashboard/event-delete.png')}`,
      eventDist: 'Event Distribution',
      eventEdit: `${require('design/icons/dashboard/event-edit.png')}`,
      eventTitles: {
        meeting: 'Project Meeting',
        presentation: 'Presentation',
        workshop: 'Robotic Workshop'
      },
      events: {
        option1: 'Event 1',
        option2: 'Event 2',
        option3: 'Event 3'
      },
      freeTime: '3:30H FREE TIME',
      newEvent: 'NEW EVENTS',
      newEventModal: {
        calendar: `${require('design/icons/dashboard/new-event-calendar.png')}`,
        clock: `${require('design/icons/dashboard/new-event-clock.png')}`,
        comments: 'Comments',
        date: 'Event Date',
        save: 'SAVE',
        time: 'Event Time',
        type: 'Select Type',
        visibility: 'Event Visibility'
      },
      types: {
        option1: 'Type 1',
        option2: 'Type 2',
        option3: 'Type 3'
      }
    },
    src: `${require('design/icons/Events/addEvent.png')}`
  },
  flyer: {
    courtesy: 'courtesy of',
    listing: 'Listing',
    paidMessage:
      'This feature is only available for paid subscriptions. Please upgrade to continue.',
    saveButton: 'Save',
    updateButton: 'Update'
  },
  generalText: {
    active: '(Active)',
    addCategorySubtype: 'ADD CATEGORY SUBTYPE',
    addEvent: 'ADD EVENT',
    addFolder: 'ADD FOLDER',
    addFolderFile: 'EDIT FILE NAME',
    addFolderName: 'EDIT FOLDER NAME',
    addInvite: '+ Invite',
    addLink: 'ADD LINK',
    addParty: 'ADD PARTY',
    addSharedWith: '+ Shared With',
    addTask: 'Add Task',
    applyWorkflow: 'APPLY WORKFLOW',
    back: 'BACK',
    changeOwnership: `${require('design/icons/transactions/checkList.png')}`,
    coming: `${require('design/icons/dashboard/coming.png')}`,
    copy: 'Copy to Clipboard',
    dummayText:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    edit: 'Edit',
    editCategory: 'EDIT CATEGORY',
    editDocument: 'EDIT DOCUMENT',
    editProperty: 'EDIT PROPERTY',
    fees: 'Fees',
    fetchData: `${require('design/icons/dashboard/fetchData.png')}`,
    image: `${require('design/images/share-with.png')}`,
    importFrom: 'Import From Google Calendar',
    invite: 'Collaborators',
    inviteCollaborator: 'Invite Collaborator',
    leader: '(Leader)',
    manager: '(Manager)',
    month: 'MONTH',
    myLeads: 'My Leads',
    notFound: 'No Teams Found',
    notes: 'MANAGER NOTES',
    nothing: `${require('design/icons/dashboard/nothing.png')}`,
    photoUpload: 'MANAGE IMAGE(S)',
    referralFee: 'Referral Fee',
    renamePass: 'Rename Pass',
    save: 'SAVE',
    selectState: 'Select a State',
    selectWorkflowType: 'Select Workflow Type',
    sharedWith: 'Shared With',
    src: `${require('design/icons/transactions/changeOwnership.png')}`,
    tax: 'Tax',
    think: 'Think',
    today: 'TODAY',
    upgrade: `${require('design/images/upgrade.png')}`,
    upgradeText: 'Big Time Upgrade',
    upload: 'Upload documents via email:',
    uploadDocsVaiEmail: `${require('design/icons/transactions/uploadDocsVaiEmail.png')}`,
    week: 'WEEK',
    workflow1: 'Workflow 1',
    workflow2: 'Workflow 2',
    workflow3: 'Workflow 3'
  },
  graph: {
    approveBtn: 'APPROVE PAYMENT',
    commission: 'Commission Due:',
    releaseBtn: 'RELEASE PAYMENT',
    totalExpenses: 'Total Expenses:',
    totalIncome: 'Total Income:'
  },
  imageZone: {
    alert: {
      delete: {
        cancel: 'No, keep it',
        confirm: 'Yes, delete it',
        description: 'You will not be able to recover it.',
        error: 'An error occurred while attempting to delete the image.',
        success: 'The image has been deleted.',
        title: 'Delete this image?'
      }
    },
    title: {
      dropBelow: 'Drop images in the area below.',
      dropClickBelow: 'Drop or click in the area below to add images.',
      organize: 'Drag images to organize.'
    }
  },
  kanbanView: {
    actionText: 'Lorem Ipsum is simaply dummay Lorem Ipsum is',
    actions: 'Actions',
    active: 'Active',
    activeOffice: 'Activate the Office',
    add: '+ Add',
    addNewBoard: 'ADD NEW BOARD',
    addTask: 'Add Task',
    appearance: 'Appearance',
    archiveSure: 'Are you sure you want to archive',
    boardName: 'boardName',
    bottomLeft: 'bottom left',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    cannotDelete:
      'You are not authorized to delete this record. Please contact your administrator if you need additional assistance.',
    cannotDeleteContact:
      'You are not authorized to delete this record. Please contact your administrator if you need additional assistance.',
    cannotDeleteLead:
      'You are not authorized to delete this record. Please contact your administrator if you need additional assistance.',
    cannotDeleteTransaction:
      'You are not authorized to delete this record. Please contact your administrator if you need additional assistance.',
    cannotMove:
      'You are not authorized to change your own role. Please contact your administrator if you need additional assistance.',
    cannotMoveLead:
      'You are not authorized to move lead. Please contact your administrator if you need additional assistance.',
    click: 'click',
    delete: 'Delete',
    deleteCard: 'Delete card',
    deleteOnlyManager:
      'Cannot delete manager, because only one manager available in the office. Create another manager in the office to delete this manager.',
    deleteTeam:
      'Deleting team-leader, will delete the team and all the team-members along with team-leader will become regular users',
    deleted: 'Deleted!',
    deletedBoard: 'Your board has been deleted.',
    deletedCard: 'Your card has been deleted.',
    deletedRecord: 'Your record has been deleted.',
    deletedTeam: 'Role Changed to Agent. And respective team has been deleted',
    description: 'Description',
    dropToDelete: 'DROP HERE TO DELETE',
    entries: 'entries',
    error: 'Error',
    errorText: 'Select records to delete them',
    export: 'Export',
    import: 'Import',
    inactivateUser: 'Inactivating the user will remove all affiliations connected with this user.',
    inactive: 'Inactive',
    inactiveOffice: 'Inactivate the Office',
    managerToTeamLeader: 'You cannot make a Manager as Team Leader directly.',
    moveCard: 'Your Card has been moved successfully',
    movedSuccessfully: 'Moved Successfully',
    movingTeam:
      'Making team-leader as an agent, will delete whole team and all of the team-members along with team-leader will become regular users',
    nameYourList: 'Name your list',
    noKeepIt: 'No, keep it',
    noKeepItActive: 'No, keep active',
    noKeepItInactive: 'No, keep inactive',
    notAllowed:
      'You are not authorized to change role to GUEST. Please contact your administrator if you need additional assistance.',
    notAllowedToAdmin:
      'You are not authorized to change role to ADMIN. Please contact your administrator if you need additional assistance.',
    of: 'of',
    onlyManager:
      'Cannot be made AGENT, because is the only manager in the office. Create another manager in the office and then make him AGENT.',
    record: 'Record',
    records: 'Records',
    recordsPerPage: 'Records per page',
    recoverBoard: 'You will not be able to recover this board and their card!',
    recoverCard: 'You will not be able to recover this card!',
    recoverOffices:
      'If you make this office Inactive, all users of this offices will also be made Inactive',
    recoverRecord: 'You will not be able to recover this record!',
    safeBoard: 'Your board is safe',
    safeCard: 'Your card is safe',
    safeRecord: 'Your record is safe',
    save: 'Save',
    search: 'Search',
    show: 'Showing',
    sort: 'Sort',
    sortAction: 'Name ASC | DESC',
    sure: 'Are you sure?',
    teamLeaderToManager: 'You cannot make a Team Leader as Manager directly.',
    text: 'Lforem Ipsum is simply dummay text of the printing and typesetting industry.',
    updateRecord: 'You will not be able to get previous status of this record!',
    updated: 'Updated!',
    updatedCard: 'Your card has been updated.',
    viewReport: 'View Report',
    yesDeleteIt: 'Yes, delete it!',
    yesMakeItActive: 'Yes, activate',
    yesMakeItInactive: 'Yes, inactivate',
    yesMoveIt: 'Yes, Move it!',
    yesUpdateIt: 'Yes, update it!'
  },
  leads: {
    Activities: 'Activities',
    Activity: 'Activity',
    DIRECT: 'DIRECT',
    IDXIntegration: 'IDX Integration',
    LEADPOOL: 'LEAD POOL',
    REASSIGNLEAD: 'REASSIGN LEAD',
    SMS: 'SMS',
    addLead: 'Add a Lead',
    addLeadSrc: `${require('design/icons/leads/AddNewLead.png')}`,
    addLeadSrc1: `${require('design/icons/leads/EditLead.png')}`,
    addSharedWith: '+ Shared With',
    addTransaction: 'Add Transaction',
    alert: {
      cancelled: 'Cancelled',
      deleted: 'Deleted!',
      noKeepIt: 'No, keep it',
      onDeleted: 'Your note has been deleted.',
      safe: 'Your note is safe',
      sure: 'Are you sure?',
      text: 'You will not be able to recover this note!',
      yesDeleteIt: 'Yes, delete it!'
    },
    archive: 'Your Lead Maria Martinez has been successfully archived.',
    archiveLead: 'Archive Lead',
    avatar: `${require('design/icons/offices/user_0001_Group-2.png')}`,
    avatar1: `${require('design/icons/offices/user_0002_Group-3.png')}`,
    avatar2: `${require('design/icons/offices/user_0003_Group-4.png')}`,
    columnMetaData: {
      id: {
        Action: 'Action',
        activities: 'activities',
        checkbox: 'checkbox',
        createdDate: 'createdAt',
        lastActionDate: 'lastAction',
        leadName: 'firstName',
        owner: 'owner',
        price: 'price',
        schedule: 'schedule',
        score: 'leadScore',
        source: 'source',
        type: 'type'
      },
      title: {
        Action: 'Action',
        activities: 'Activities',
        createdDate: 'Created Date',
        lastActionDate: 'Last Action Date',
        leadName: 'Lead Name',
        owner: 'Owner',
        schedule: 'Schedule',
        score: 'Score',
        source: 'Source',
        status: 'Status',
        type: 'Type'
      }
    },
    columnPropertyPassMetaData: {
      id: {
        Action: 'Action',
        bedroomsBathrooms: 'bedroomsBathrooms',
        checkbox: 'checkbox',
        priceRange: 'priceRange',
        propertyType: 'propertyType',
        timeFrame: 'timeFrame'
      },
      title: {
        Action: 'Action',
        bedroomsBathrooms: 'Bedrooms / Bathrooms',
        priceRange: 'Price Range',
        propertyType: 'Property Type',
        timeFrame: 'Time Frame'
      }
    },
    columnWorkflowMetaData: {
      id: {
        Action: 'Action',
        assignedTo: 'firstName',
        checkbox: 'checkbox',
        dueIn: 'dueDate',
        status: 'status',
        task: 'name',
        taskDescription: 'description'
      },
      title: {
        Action: 'Action',
        assignedTo: 'Assigned To',
        dueIn: 'Due',
        status: 'Status',
        task: 'Task',
        taskDescription: 'Task Description'
      }
    },
    createTransaction: 'Create Transaction',
    dateValue: '2 Days Ago',
    delete: 'Delete',
    edit: 'Edit',
    emailDrip: 'Email Drip',
    emailId: 'Email ID',
    errorLeadText: 'Select records to delete lead',
    followUpTask: 'Follow Up Task',
    history: 'History',
    image: `${require('design/images/share-with.png')}`,
    leadArchieve: `${require('design/images/Lead_archieve.png')}`,
    makeCompleted: 'Make Completed',
    makeInProgress: 'Make In Progress',
    notes: 'NOTES',
    notesName: 'Notes Name',
    optional: '(Optional)',
    owner: 'Owner : ',
    phone: 'Phone No.',
    popupText:
      'Lforem Ipsum is simply dummay text of the printing and typesetting.',
    propertyPass: 'PropertyPass',
    range: 'Range: ',
    rangeValue: '$10 - $100',
    reassignLead: 'Reassign Lead',
    referralFeeRequested: 'Referral Fee Requested',
    relatedTo: 'Related to : ',
    renter: 'Renter',
    sendEmail: 'Send Email',
    shareWith: 'Share With',
    sharedWith: 'Shared With',
    src: `${require('design/icons/leads/Reassign-Lead.png')}`,
    src1: `${require('design/icons/leads/Lead-Pool.png')}`,
    status: 'Status',
    text:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing',
    title: 'List Title',
    type: 'Type : ',
    view: 'View Details',
    workflow: 'Workflow'
  },
  mailbox: {
    alert: {
      delete: {
        cancel: 'No, keep it',
        confirm: 'Yes, delete it',
        description: 'You will not be able to recover it.',
        error: 'An error occurred while attempting to delete the message.',
        success: 'The message has been deleted.',
        title: 'Delete this message?'
      }
    },
    sidebar: {
      compose: 'Compose',
      drafts: 'Drafts',
      inbox: 'Inbox',
      sent: 'Sent',
      starred: 'Starred',
      trash: 'Trash'
    }
  },
  office: {
    Action: 'Action',
    BranchName: 'Branch Name',
    Disclaimer: 'Disclaimer',
    Location: 'Location',
    Manager: 'Manager',
    Status: 'Status',
    TeamMembers: 'Team Members',
    action: 'action',
    activeUsers: 'Active Users',
    addOffice: 'Add Office',
    avatar: `${require('design/icons/offices/user_0001_Group-2.png')}`,
    avatar1: `${require('design/icons/offices/user_0002_Group-3.png')}`,
    avatar2: `${require('design/icons/offices/user_0003_Group-4.png')}`,
    branchName: 'Branch Name : ',
    branchNames: 'branchName',
    checkbox: 'checkbox',
    defaultProfile: 'https://image.flaticon.com/icons/png/512/149/149071.png',
    delete: 'Delete',
    disclaimerText:
      'This new office will cost an additional $97/month and that this branch office will share the overall transaction budget limits already set with the current subscription level.',
    edit: 'Edit',
    editOffice: 'Edit Office',
    error: 'Error...!',
    location: 'location',
    manager: 'firstName',
    managerName: 'Manager Name',
    managers: '(Manager)',
    migrate: 'Migrate data to an existing office?',
    noManager: 'No Manager',
    noOf: 'No. of Teams',
    somethingWentWrong: 'Something went wrong.',
    src: `${require('design/icons/offices/add_office_clip_art.png')}`,
    src1: `${require('design/icons/offices/EditOffice.png')}`,
    status: 'status',
    teamMembers: 'Team Members : ',
    teamMemberse: 'teamMembers',
    text:
      'Lforem Ipsum is simply dummay text of the printing and typesetting industry',
    transactions: 'Transactions',
    viewDetails: 'View Details',
    viewReport: 'View Report'
  },
  onboarding: {
    approved: 'APPROVED',
    company: 'REALTYPASS, INC.',
    concierge: {
      link: `Let's go!`,
      subtitle: 'Let us create your files.',
      title: 'In a hurry?'
    },
    confirm: 'Confirm your account.',
    confirmation: '123456',
    description: `Enter your email address and we'll send you a link to reset your password.`,
    featureHeaderLine1: 'Amazing Features',
    featureHeaderLine2: 'Included',
    featureHeaderLine3: 'with Your Pass:',
    features: [
      'Unlimited Users',
      'Unlimited Workflows',
      'Paperless Office Solution',
      'Lead Distribution',
      'Digital Signature Integration',
      'Automated Tax Reporting',
      'Team & Brokerage Support',
      'Dozens of Integrations'
    ],
    forgot: 'Forgot your password?',
    inputPassword: 'Password',
    inputUsername: 'Email address',
    invalid: 'Invalid Username or Password',
    loading: 'Loading...',
    locked: 'LOCKED?',
    login: 'Login',
    loginAlternative: 'Or',
    loginFacebook: 'Login with Facebook',
    loginGoogle: 'Login with Google',
    logo: `${require('design/images/logo.png')}`,
    nextButton: 'Ready!',
    notMember: 'Not a member? Sign up!',
    policy: '| Acceptable Use Policy',
    resetPassword: 'Reset Password',
    src: `${require('design/images/onboarding/kennel.png')}`,
    terms: ' | Terms of Service ',
    verification: 'Enter verification code',

    PolicyPdf: `${require('design/Pdf/ACCEPTABLE_USE_POLICY.pdf')}`,
    termsPdf: `${require('design/Pdf/TERMS_OF_SERVICE.pdf')}`
  },
  pieChart: {
    expenses: 'Expenses',
    income: 'Income'
  },
  reports: {
    addNewReport: 'Add New Report',
    applyYourFilters: 'Apply Your Filters',
    back: 'BACK',
    cancel: 'RESET',
    cancelled: 'Cancelled',
    customizeReport: 'Customize Report',
    deleted: 'Deleted!',
    deletedReport: 'Your report has been deleted.',
    discription:
      'This report will be saved under my report. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    noKeepIt: 'No, keep it',
    number1: '1',
    number2: '2',
    number3: '3',
    pickYourData: 'Pick Your Data',
    preview: 'SAVE AND PREVIEW',
    print: 'Print',
    recoverReport: 'You will not be able to recover this card!',
    reportType: 'Report Type',
    safeReport: 'Your report is safe',
    src: `${require('design/icons/reports/create-report.png')}`,
    sure: 'Are you sure?',
    totalCommissionReceived: 'Total Commission Received',
    totalDue: 'Total Due',
    totalReferrals: 'Total Referrals',
    totalPrice: 'Total Price',
    totalResults: 'Total Results',
    transaction: 'VOLUME REPORT',
    yesDeleteIt: 'Yes, delete it!'
  },
  settings: {
    Category: 'Add New Category',
    addCategory: '+ Add New Category',
    addProperty: '+ Add Property Type',
    addString: '+ Add Document',
    checkedBtn: `${require('design/icons/settings/checked.png')}`,
    closeBtn: `${require('design/icons/settings/close.png')}`,
    configuration: {
      agentReferralBonus: 'Agent Referral Bonus',
      batchImport: 'Batch Import Max. Limit',
      dailyOutgoing: 'Daily Outgoing Email Limit',
      displayAgent: 'Display Agent Ranking',
      duplicatePayment: 'Duplicate Payment Tolerance',
      duplicatePaymentMessage:
        'Difference value to make sure that the user is not making duplicate payment of any transaction',
      gamification: 'Gamification',
      generalConfiguration: 'General Configuration',
      maxAgentReferrals: 'Max Agent Referrals',
      numberOfAgent:
        'Number of agents referred to brokerage before monthly dues are waived',
      permitted: 'Maximum Team Size Permitted',
      rankingSystem: 'Ranking Sub-System',
      userConfiguration: 'User Configuration'
    },
    customPaln: `${require('design/icons/settings/Add-Custom-Plan_Graphic.png')}`,
    customize: 'customize',
    headerDeleteBtn: `${require('design/icons/settings/delete.png')}`,
    minusBtn: `${require('design/icons/settings/minus.png')}`,
    newCategory: 'New Category',
    notification: {
      all: 'All alerts have been read',
      createNotification: 'Create',
      discard: 'Discard all alerts',
      email: 'Email',
      feed: 'FEED',
      goalNotification: 'Goals Notification',
      leadNotification: 'Lead Notification',
      noAlert: 'No alert',
      notification: 'Action/Trigger',
      realtyPass: 'RealtyPass',
      sound: 'Sound',
      systemNotification: 'System Notification',
      systemNotificationDescription:
        'Predefined alerts are available for these modules which can be turned on or off based on your business needs.',
      teamNotification: 'Team Notification',
      text: 'systemNotificationDescription',
      transactionNotification: 'Transactions Notification',
      updates: 'PUBLIC'
    },
    plans: {
      allPlans: 'All Plans Include',
      amet: 'Amet consecteture adipisic',
      areYouReady:
        'Are you ready to expand your business? We offer addtional office accounts that share your pool of transaction under one roof with great ease for only $97/month per office',
      credit: '(no  credit card required)',
      each: `We've pre-loaded each plan with many advanced passes and integrations to seamlessly connect your 3rd party applications`,
      growth: 'That Scale With Your Growth',
      loaded: 'Loaded with dozens of passes and integrations',
      lorem: 'Lorem ipsum dolor sit',
      month: 'month',
      months: 'Month',
      number: '47',
      perAddtionalOffice: 'PRE ADDTIONAL OFFICE',
      perfect: 'Perfect brokerage of Franchise building solution',
      plansAndFeature: 'Tier Based Pricing ',
      sedDo: 'Sed do eiusmod tempor',
      tiered: 'Tiered Pricing',
      weUnserstand: `We unserstand that are in the real estate business, not predicting how much or whom will product on a monthly basis. you get billed for the transaction tier you need. we don't penalize you for growing your team like others to by levying a 'per-user' monthly charge. We win when you do. it's a fair and honest pricing approach`
    },
    plusBtn: `${require('design/icons/settings/plus.png')}`,
    profile: {
      BILLINGDETAILS: 'PAYMENT HISTORY',
      billingAddress: 'Billing Address',
      brandingLogo: 'Branding Logo',
      company: 'Company',
      dateFormat: 'Format 01/24/2019 and 1,234.56',
      localization: 'Contact / Localization',
      shippingAddress: 'Shipping Address',
      social: 'Social',
      src: `${require('design/icons/settings/setting/Payment-History.png')}`,
      text:
        'Lforem Ipsum is simply dummay text of the printing and typesetting industry'
    },
    propertyType: 'property type',
    settings: 'Settings',
    taxSetup: {
      addTex: 'Add Tax',
      categoryDeleted: 'Your Category has been deleted and their property',
      categorySafed: 'Your Category and their property is safe',
      columnMetaData: {
        Action: 'Action',
        City: 'City',
        LocalTax: 'Local Tax (%)',
        State: 'State',
        StateTax: 'State Tax (%)',
        checkbox: 'checkbox',
        city: 'city',
        localTax: 'localTax',
        state: 'state',
        stateTax: 'stateTax'
      },
      delete: 'Delete',
      edit: 'Edit',
      propertyDeleted: 'Your Property has been deleted',
      propertySafed: 'Your property is safe',
      recoverCategory:
        'You will not be able to recover this category and their property types!',
      recoverProperty:
        'You will not be able to recover this property and their settings!',
      src: `${require('design/icons/settings/Add-Tax.png')}`,
      taxRate: `${require('design/icons/settings/TaxMascot-Graphic.png')}`
    },
    taxSetupDescription: 'Contrary to popular belief',
    taxSetupDetails:
      'Manage all of your State and local taxes from here. Once set you can use these settings through out the application.',
    taxSetupTitle: 'Tax Setup',
    transactionFeeDescription: 'Contrary to popular belief',
    transactionFeeDetails:
      'Manage all fees pertaining to transaction from here. You can set Transaction fees, commission fees, capping limit and any other custom defined fees to make it seemline with your system.',
    transactionFeeTitle: 'Tansaction Fee',
    uncheckedBtn: `${require('design/icons/settings/unchecked.png')}`,
    welcomeOnboard: 'Welcome Onboard. Let us help you get started.',
    workflowManagerDescription: 'Contrary to popular belief',
    workflowManagerDetails:
      'Workflow Manager lets you manage and share tasks easliy within your teams and company',
    workflowManagerTitle: 'Workflow Manager'
  },
  sidePanel: {
    addTransaction: 'Add Transaction',
    header: 'Transactions',
    viewAll: 'View All'
  },
  styleTypes: {
    colChange: 'col-change',
    dynamic: 'dynamic',
    item: 'item',
    stationary: 'stationary'
  },
  tasks: {
    ADDTASK: 'ADD TASK',
    activity: 'Activity',
    addTask: 'Add Task Name',
    avatar: `${require('design/icons/offices/user_0001_Group-2.png')}`,
    avatar1: `${require('design/icons/tasks/user_0002_Group-3.png')}`,
    avatar2: `${require('design/icons/tasks/user_0003_Group-4.png')}`,
    dueDateImage: `${require('design/icons/tasks/setDueDate.png')}`,
    errorTaskText: 'Select task records to delete task(s)',
    errorUpdateTaskText: 'Select task records to update them',
    noteAddImage: `${require('design/icons/tasks/Add.png')}`,
    notes: 'Notes',
    relatedTo: 'Related To : ',
    selectTask: 'Select Task',
    sharedWith: 'Invite Attendees',
    src: `${require('design/icons/tasks/AddNewTask.png')}`,
    src1: `${require('design/icons/tasks/EditTask.png')}`,
    text:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing'
  },
  tasksSetting: {
    ADD: 'ADD',
    ADDLABEL: 'ADD LABEL',
    ADDTASK: '+ Add Task',
    ADDWORKFLOW: '+ Add Workflow',
    FSBOListingProcess: 'FSBO Listing Process',
    INVITETEAM: 'INVITETEAM',
    action: 'Action',
    assignLabel: 'Assign Label',
    cancelled: 'Cancelled',
    deleteWorkflow: 'Delete',
    deleted: 'Deleted!',
    deletedTask: 'Your task has been deleted.',
    deletedWorkflow: 'Your workflow has been deleted.',
    dueDays: 'Due',
    dueIn: 'Due in',
    edit: 'Edit',
    foure: '+4',
    manageLabel: 'MANAGE LABELS',
    noKeepIt: 'No, keep it',
    pickColor: 'Pick a Color',
    recoverTask: 'You will not be able to recover this task!',
    recoverWorkflow: 'You will not be able to recover this workflow!',
    safeTask: 'Your task is safe',
    safeWorkflow: 'Your workflow is safe',
    selectLabel: 'Select Label',
    src: `${require('design/icons/settings/workflowManager/AddWorkflow.png')}`,
    sure: 'Are you sure?',
    tags: 'Tags :',
    taskSharedWith: 'Task Shared With :',
    viewWorkflow: 'View',
    workflowManager: 'Workflow Manager',
    workflowTask: 'Workflow Task',
    yesDeleteIt: 'Yes, delete it!'
  },
  teams: {
    Action: 'Action',
    CAPContribution: 'CAP Contribution',
    ResidentialCAPContribution: 'Residential CAP Contribution',
    CommercialCAPContribution: 'Commercial CAP Contribution',
    PandingInvites: 'Pending Invites',
    TeamCAP: 'Residential/Commercial CAP',
    TeamLeader: 'Team Leader',
    TeamMembers: 'Team Members',
    TeamName: 'Team Name',
    TeamOffice: 'Team Office',
    action: 'action',
    addTeam: 'Add Team',
    affiliateOffice: 'Affiliate Office :',
    avatar: `${require('design/icons/offices/user_0001_Group-2.png')}`,
    avatar1: `${require('design/icons/offices/user_0002_Group-3.png')}`,
    avatar2: `${require('design/icons/offices/user_0003_Group-4.png')}`,
    branchName: 'branchName',
    cancelInvitation: 'Are you sure that you want to cancel this invitation ?',
    checkbox: 'checkbox',
    commercial: 'Commercial : ',
    commission: 'Commission',
    commissionEarned: 'Commission Earned',
    commissionSplit: 'Commission Split',
    completed: 'COMPLETED CAP',
    residentialCompleted: 'RESIDENTIAL',
    commercialCompleted: 'COMMERCIAL',
    createdOn: 'Created On : ',
    delete: 'Delete Team',
    deleteInvitation: 'Your Invitation has been deleted',
    edit: 'Edit Team',
    error: 'Error...!',
    errorTeamText: 'Select records to delete team',
    fee: 'Fee',
    invitationDate: 'Invitation Date',
    leader: 'Leader : ',
    members: 'Members',
    noMembers: 'No Members',
    noOf: '# Closed Transactions',
    noPending: 'No Pending Invities',
    pandingInvites: 'pendingInvites',
    recoverInvitation: 'You will not be able to recover this invitation!',
    residential: 'Residential : ',
    safeInvitation: 'Your invitation is safe',
    somethingWentWrong: 'Something went wrong.',
    src: `${require('design/icons/teams/NewTeam.png')}`,
    src1: `${require('design/icons/teams/EditTeam.png')}`,
    team: 'Team',
    teamAgreement: 'Team Agreement :',
    teamCAP: 'cap',
    teamCap: 'Team CAP',
    teamFee: 'Team Fee',
    teamLead: 'Team Lead :',
    teamJoiningDate: 'Lead Team Joining Date',
    teamLeader: 'firstName',
    teamLeaders: 'Team Leader',
    teamMembers: 'teamMembers',
    teamName: 'teamName',
    text:
      'Lforem Ipsum is simply dummay text of the printing and typesetting industry',
    viewDetails: 'View Details',
    viewReport: 'View Report',
    transactionFee: 'Total Transaction Fee',
    commissionFee: 'Total Commission Fee'
  },
  transactionActivityTab: {
    today: 'Today',
    userIcon: `${require('design/icons/transactions/activity/avatar.png')}`,
    yesterday: 'Yesterday'
  },
  transactionCommissionTab: {
    checklist: 'Import Checklist',
    expenses: {
      addFee: {
        amount: 'Amount',
        feeName: 'Type Fee Name'
      },
      addImage: `${require('design/icons/transactions/commission/add.png')}`,
      addReferralFee: {
        addValue: 'Value',
        selectAmount: 'Amount',
        selectType: 'Select Referral Type'
      },
      fees: 'Fees',
      plus: '+',
      referralFee: 'Referral Fee',
      title: 'Expenses'
    },
    income: {
      calendar: `${require('design/icons/transactions/commission/calendar.png')}`,
      dateDown: `${require('design/icons/transactions/commission/down.png')}`,
      dateUp: `${require('design/icons/transactions/commission/up.png')}`,
      description: 'Expected/Actual Commission',
      paidDate: 'Paid Date',
      receivedDate: 'Received Date',
      title: 'Income'
    }
  },
  transactionDashboard: {
    addDocumentImage: `${require('design/icons/transactions/Add_Document.png')}`,
    addTransaction: {
      mlsId: 'MLS ID : ',
      src: `${require('design/icons/transactions/Add_Transaction.png')}`,
      src1: `${require('design/icons/transactions/Edit_Transaction.png')}`,
      text:
        'Lforem Ipsum is simply dummay text of the printing and typesetting industry',
      texts:
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing'
    },
    avatar: `${require('design/icons/transactions/user_0001_Group-2.png')}`,
    avatar1: `${require('design/icons/transactions/user_0002_Group-3.png')}`,
    avatar2: `${require('design/icons/transactions/user_0003_Group-4.png')}`,
    changeOwnership: 'Change Ownership',
    column: {
      card: {
        footer: {
          subText: 'Records'
        },
        header: {
          noTitle: 'List Title'
        },
        mslID: 'MSL ID:',
        progressImage: `${require('design/icons/transactions/dashboard/progress-info.png')}`,
        type: {
          buy: 'BUY',
          sell: 'SELL'
        }
      },
      paymentApproved: `${require('design/icons/transactions/Payment-Approved.png')}`,
      paymentReleased: `${require('design/icons/transactions/Transaction-Complete.png')}`
    },
    delete: 'Delete',
    image: `${require('design/icons/transactions/transaction.png')}`,
    inviteeModal: {
      attach: 'Attach',
      brokerage: 'Brokerage Name',
      composeEmail: 'COMPOSE EMAIL',
      deleteDraft: 'Delete Draft',
      editInvitee: 'Edit Invitee',
      optional: '(Optional)',
      src: 'https://react.semantic-ui.com/images/wireframe/image.png',
      template: 'Select Email Template',
      text:
        'Lforem Ipsum is simply dummay text of the printing and typesetting industry'
    },
    mls: `${require('design/icons/transactions/IDX_Graphic.png')}`,
    sideBar: {
      slider: {
        nextBtn: `${require('design/icons/transactions/dashboard/right.png')}`,
        prevBtn: `${require('design/icons/transactions/dashboard/left.png')}`
      }
    },
    viewDetails: 'View Details',
    viewNotes: 'View Notes'
  },
  transactionDocumentTab: {
    documentList: {
      document: {
        banIcon: `${require('design/icons/transactions/documents/minus.png')}`,
        completedIcon: `${require('design/icons/transactions/documents/check-green.png')}`,
        recoverDocument: 'You will not be able to recover this document',
        removeIcon: `${require('design/icons/transactions/documents/cancel.png')}`
      }
    },
    documentOverview: {
      icons: {
        lockNewPdf: `${require('design/icons/transactions/documents/newLockPDF.png')}`,
        lockPdf: `${require('design/icons/transactions/documents/lock.png')}`,
        newPdf: `${require('design/icons/transactions/documents/newPDF.png')}`,
        pdf: `${require('design/icons/transactions/documents/PDF.png')}`
      },
      menuItems: {
        delete: 'Delete',
        email: 'Email',
        esign: 'E - Sign',
        lock: 'Lock',
        rename: 'Rename',
        splitFile: 'Split File',
        unlock: 'Unlock',
        view: 'View'
      },
      splitFile: {
        no: 'NO',
        rememberChoice: 'Remeber My Choice',
        text: 'Do you want to split the document for specific checklist items?',
        yes: 'YES'
      },
      upload: {
        uploadVia: 'Or Upload Via:',
        uploadViaDropBox: `${require('design/icons/transactions/documents/dropbox.png')}`,
        uploadViaGoogleDrive: `${require('design/icons/transactions/documents/google-drive.png')}`
      }
    },
    documentWindow: {
      backBtn: `${require('design/icons/transactions/documents/delete.png')}`,
      leftBtn: `${require('design/icons/transactions/documents/left.png')}`,
      next: 'NEXT',
      previous: 'PREVIOUS',
      rightBtn: `${require('design/icons/transactions/documents/right.png')}`
    }
  },
  transactionFeeSettings: {
    addMore: 'Add More',
    baseFee: {
      header: 'Base Fee',
      info: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
      label: 'Base Fee'
    },
    capGroups: {
      additionalCap: 'Additional Member',
      additionalCapInfo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
      commercialCap: 'Commercial CAP',
      commercialCapInfo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
      individualUser: 'INDIVIDUAL USER',
      residentialCap: 'Residential CAP',
      residentialCapInfo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
      team: 'TEAM',
      teamCap: 'Team CAP',
      teamCapInfo: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
      teamResidentialCap: 'Base Residential CAP',
      teamCommercialCap: 'Base Commercial CAP'
    },
    capHeading: 'CAP',
    delete: 'Delete',
    enterAmount: 'Enter Amount..',
    enterFeeLabel: 'Enter Fee',
    enterPercentFeeLabel: 'Enter % Fee',
    exclusionCap: {
      content: 'Mega transactions will be excluded from capping for residential and commercial. Enter a Value below that you would like to consider as mega transactions.',
      heading: 'EXCLUSIONS'
    },
    expectedCommission: {
      graphLabel: 'Commission'
    },
    expectedCommissionLabel: 'Expected Commission',
    fixed: {
      key: 'FIXED',
      label: 'Fixed'
    },
    flat: {
      key: 'FLAT_FEE',
      label: 'Flat'
    },
    flatPercent: {
      key: 'FLAT_FEE_PERCENTAGE',
      label: 'Flat Percentage Fee'
    },
    hybrid: {
      key: 'HYBRID_FEE',
      label: 'Hybrid Fee'
    },
    maximum: 'Max',
    minimum: 'Min',
    noFee: {
      key: 'NO_FEE',
      label: 'No Fee'
    },
    percent: 'Percent %',
    percentage: {
      key: 'PERCENTAGE',
      label: 'Percentage'
    },
    priceIncrement: {
      header: 'Price Increment',
      info: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
      label: 'An Additional'
    },
    progressive: {
      key: 'PROGRESSIVE_FEE',
      label: 'Progressive Fee'
    },
    src: `${require('design/icons/transactions/referral.png')}`,
    tabs: {
      capSettings: 'CAPS / Limit',
      customPlan: 'Custom Plans',
      deletedTransactionSetting: 'Your Transaction Setting has been deleted.',
      errorDeletingTransactionSetting: 'This Transaction Setting is used. Failed to delete it.',
      feeSettings: 'Fee Settings',
      recoverDeleteSettings: 'You wont be able to recover this Transaction Setting',
      safeTransactionSetting: 'Your Transaction Setting is saved',
      sureDeleteSettings: 'Are you sure you want to delete this Transaction Setting ?'
    },
    transactionFeeLabel: 'Transaction Fee',
    types: {
      commercial: {
        key: 'COMMERCIAL',
        name: 'Commercial Fee'
      },
      commission: {
        key: 'COMMISSION',
        name: 'Commission Fee'
      },
      other: {
        key: 'OTHER',
        name: 'Other Fees'
      },
      residential: {
        key: 'RESIDENTIAL',
        name: 'Residential Fee'
      },
      total: {
        key: 'TOTAL',
        name: 'Total Commission Due'
      }
    }
  },
  transactionMessageTab: {
    messages: {
      otherMsg: {
        replyAll: `${require('design/icons/transactions/messages/reply-all.png')}`,
        star: `${require('design/icons/transactions/messages/star-stroke.png')}`
      }
    },
    topBar: {
      star: `${require('design/icons/transactions/messages/star.png')}`,
      today: 'TODAY'
    }
  },
  transactionModal: {
    CONCIERGE: {
      DESCRIPTION: {
        final1: `We'll send you an email when your transaction has been processed.`,
        idx1: `Fetch the property data for your transaction by entering its MLS-listed ID.`,
        idx2: `Please note: only properties that have been listed by your MLS's IDX data feed are available to fetch.`,
        intro1: `First, we need to confirm some office details.`
      },
      INPUT: {
        emailLabel: `What email address do you use with RealtyPass?`,
        emailPlaceholder: 'Email address',
        emailSubmit: 'Submit',
        finalAgain: `Create another transaction?`,
        finalClose: `Close`,
        idxPlaceholder: `Listed ID`,
        idxSubmit: `Fetch`,
        multiDocumentSubmit: `All Set`,
        multiDocumentSubmitNone: `No Thanks`,
        officeLabel: `You belong to several offices. Please select one for this transaction.`,
        officeSubmit: `Continue`
      },
      TITLE: {
        document1: `Looks good.`,
        final1: `Great! We'll take it from here.`,
        idx1: `Your office has IDX enabled!`,
        idx2: `Here's the listing we found.`,
        intro1: `Let's create a transaction.`,
        multiDocument1: `Add documents to your transaction.`,
        office1: `We need a few more details.`
      }
    },
    image: {
      add: `${require('design/icons/transactions/Add_Transaction.png')}`,
      concierge: `{require('design/images/onboarding/concierge-dog.png')}`,
      edit: `${require('design/icons/transactions/Edit_Transaction.png')}`,
      idx: `${require('design/icons/transactions/IDX_Graphic.png')}`
    }
  },
  transactionNavigation: {
    backBtn: 'Back',
    toolsBtn: {
      image: `${require('design/icons/transactions/header/tools.png')}`,
      text: 'Tools'
    }
  },
  transactionSidebar: {
    activeBtn: 'Active',
    addDetailsBtn: {
      btnText: 'Add More Details',
      source: `${require('design/icons/transactions/sidebar/add-details.png')}`
    },
    addPerson: {
      image: `${require('design/icons/transactions/sidebar/add-person.png')}`,
      plus: '+',
      text: 'Invite Parties'
    },
    addPersonModal: {
      selectRole: 'Select Role'
    },
    deactiveBtn: 'Deactivated',
    detailsBtn: 'View Full Details',
    photoUpload: {
      closeBtn: '',
      tabTexts: {
        mediaLibrary: 'Media Library',
        mlsUpload: 'MLS Upload',
        uploadImage: 'Upload Image'
      },
      uploadIcon: `${require('design/icons/transactions/sidebar/upload.png')}`,
      uploadLink: 'Click Here To Upload',
      uploadLinkPrefix: 'Drag and Drop PDF Files Here Or',
      uploadLinkPrefixImage: 'Drag and Drop Image Here Or'
    },
    tabIDs: {
      attachments: 'attachments',
      commission: 'commission',
      docs: 'documents',
      notifications: 'notifications'
    }
  },
  transactionWorkflowTab: {
    addTask: {
      placeholders: {
        date: 'Due Date',
        desc: 'Description',
        name: 'Task Name'
      }
    },
    addTastBtn: `${require('design/icons/transactions/workflow/add-task.png')}`,
    delete: 'Delete',
    deleteBtn: `${require('design/icons/transactions/workflow/delete.png')}`,
    editBtn: `${require('design/icons/transactions/workflow/edit.png')}`,
    markCompleted: 'Mark Completed',
    share: 'Share',
    table: {
      assignedTo: 'Assigned To',
      dueDate: 'Due Date',
      taskName: 'Task Name'
    },
    view: 'View'
  },
  userProfile: {
    addAccTransaction: {
      amount: 'Amount',
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      feeName: 'Fee Name',
      referral: `${require('design/icons/userProfile/referral.png')}`,
      save: 'SAVE',
      src: `${require('design/icons/userProfile/credit.png')}`,
      status: 'Status',
      statusOptions: {
        notApplicable: {
          key: 'Not Applicable',
          label: 'NA'
        },
        paid: {
          key: 'Paid',
          label: 'Paid'
        },
        unpaid: {
          key: 'Unpaid',
          label: 'Unpaid'
        }
      },
      transactionType: 'Transaction Type',
      transactionTypeOptions: {
        credit: {
          key: 'Credit',
          label: 'Credit'
        },
        debit: {
          key: 'Debit',
          label: 'Debit'
        }
      }
    },
    changePassword: {
      confirmPassword: 'Confirm Password',
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      newPassword: 'New Password',
      oldPassword: 'Old Password',
      save: 'SAVE',
      title: 'CHANGE PASSWORD'
    },
    defaultProfileImage:
      'https://image.flaticon.com/icons/png/512/149/149071.png',
    detailSection: {
      accounts: {
        columns: {
          action: {
            id: 'action',
            name: 'Action'
          },
          amount: {
            id: 'amount',
            name: 'Amount'
          },
          date: {
            id: 'createdAt',
            name: 'Added Date'
          },
          feeName: {
            id: 'feeName',
            name: 'Fee Name'
          },
          paid: {
            id: 'paid',
            name: 'Status'
          },
          transactionId: {
            id: 'transactionId',
            name: 'Transaction'
          }
        },
        deleteLabel: 'Delete',
        editLabel: 'Edit',
        openLink: 'Open Link',
        paidLabel: 'Paid',
        unpaidLabel: 'Unpaid'
      },
      basicDetails: {
        address: 'Address',
        bioPlaceholder: 'Enter Bio',
        contactInfo: 'Contact Info',
        editLicenceExpiration: 'Enter Licence Expiration',
        editLicenceNo: 'Enter Licence No',
        editMlsId: 'Enter MLS ID',
        editWebsite: 'Enter Website URL',
        fbPlaceholder: 'Facebook URL',
        homeAddressPlaceholder: 'Enter Home Address',
        licenceExpiration: 'License Expiration',
        licenceNo: 'License No',
        linkedinPlaceholder: 'Linkedin URL',
        mlsId: 'MLS ID',
        mobilePhonePlaceholder: 'Enter Mobile No.',
        officeAddressPlaceholder: 'Enter Office Address',
        phonePlaceholder: 'Enter Phone No.',
        rosterName: 'Roster Name',
        rosterNamePlaceholder: 'Enter Roster Name',
        socials: 'Socials',
        twitterPlaceholder: 'Twitter URL'
      },
      links: {
        columns: {
          action: {
            id: 'action',
            name: 'Action'
          },
          link: {
            id: 'url',
            name: 'Link'
          },
          name: {
            id: 'name',
            name: 'Name'
          }
        }
      },
      myDocuments: {
        actionButtons: {
          uploadFile: 'Upload File',
          uploadFolder: 'Upload Folder'
        },
        actions: {
          delete: 'Delete',
          download: 'Download',
          esign: 'E-Sign',
          move: 'Move',
          rename: 'Rename'
        },
        columns: {
          action: {
            id: 'action',
            title: 'Action'
          },
          fileName: {
            id: 'fileName',
            title: 'Name'
          },
          modified: {
            id: 'modified',
            title: 'Modified'
          }
        },
        company: 'Company',
        documents: 'Documents',
        fileType: 'FILE',
        folderType: 'FOLDER',
        mine: 'Mine',
        none: 'NONE',
        sharedFolderType: 'SHARED_FOLDER',
        types: {
          file: 'FILE',
          folder: 'FOLDER',
          sharedFolder: 'SHARED_FOLDER'
        }
      },
      tabs: {
        accounts: {
          key: 'accounts',
          title: 'Credit / Debit'
        },
        basicDetails: {
          key: 'basicDetails',
          title: 'Basic Details'
        },
        links: {
          key: 'links',
          title: 'Links'
        },
        myDocuments: {
          key: 'myDocuments',
          title: 'Documents'
        },
        paymentInfo: {
          key: 'paymentInfo',
          title: 'Account Info'
        },
        teams: {
          key: 'teams',
          title: 'Teams'
        }
      }
    },
    graphSection: {
      added: 'Added',
      changePassword: 'Change Password',
      commercialCAP: 'Commercial CAP',
      goals: 'of Sales goal',
      leads: 'Leads',
      month: 'Vs Last Month',
      new: 'New',
      paymentHistory: 'Payment History',
      paymentInfo: 'Payment Info',
      residentialCAP: 'Residential CAP',
      revenue: 'Revenue (YTD)',
      revenueTracker: 'Revenue Tracker',
      sales: 'Sales Goal',
      totalReferrals: 'Total Referrals',
      transactions: 'Transactions'
    },
    paymentHistory: {
      download: 'Download',
      export: 'Export',
      paid: {
        class: 'paid',
        key: 'PAID',
        label: 'Paid'
      },
      pending: {
        class: 'pending',
        key: 'PENDING',
        label: 'Pending'
      },
      src: `${require('design/icons/userProfile/Payment-history.png')}`,
      title: 'VIEW PAYMENT HISTORY',
      view: 'View'
    },
    paymentInfo: {
      accountNumber: 'Bank Account Number',
      bankName: 'Bank Name',
      einNumber: 'EIN Number',
      paymentMethod: 'Payment Method',
      routingNumber: 'Bank Routing Number',
      src: `${require('design/icons/userProfile/Payment-info.png')}`,
      taxId: 'Tax ID',
      taxReportingName: 'Tax Reporting Name',
      title: 'PAYMENT INFO',
    },
    referralPopup: {
      all: {
        label: 'All',
        value: 'ALL'
      },
      joined: {
        label: 'Joined',
        value: 'JOINED'
      },
      pending: {
        label: 'Pending',
        value: 'PENDING'
      },
      referralsList: {
        title: 'Refer A Friend'
      },
      statistics: {
        copyAndShare: 'Copy & Share',
        description:
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
        inviteSent: 'Invite Sent',
        title: 'Referral Statistics'
      }
    },
    userInfo: {
      badges: 'Badges',
      contractDocument: 'Contract Document',
      joined: 'Joined',
      languagesSpoken: 'Languages Spoken',
      lastLogin: 'Last Login',
      memberSince: 'Member Since',
      notLoggedIn: 'Not Logged In Yet',
      pending: 'Pending',
      referFriend: 'Your Vital Statistics',
      referrals: 'Refer A Friend',
      storage: 'Storage:',
      totalReferrals: 'Total Referrals : '
    }
  },
  users: {
    addUser: {
      addUser: 'ADD USER',
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      editUser: 'EDIT USER',
      email: 'Email',
      firstName: 'First Name',
      lastName: 'Last Name',
      phone: 'Phone No.',
      save: 'SAVE',
      src: `${require('design/icons/users/AddNewUser.png')}`,
      src1: `${require('design/icons/users/EditUser.png')}`,
      text:
        'Lforem Ipsum is simply dummay text of the printing and typesetting industry',
      title: 'ADD USER'
    },
    gridView: {
      action: {
        id: 'action',
        title: 'Action'
      },
      cap: {
        id: 'cap',
        title: 'Res. CAP / Com. CAP'
      },
      name: {
        id: 'firstName',
        title: 'Name'
      },
      office: {
        id: 'office',
        title: 'Office'
      },
      role: {
        id: 'role',
        title: 'Role'
      },
      status: {
        id: 'status',
        title: 'Status'
      },
      user: {
        id: 'profileImage',
        title: 'User'
      }
    },
    importContacts: {
      agreement: 'Agreement',
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      download: 'DOWNLOAD',
      downloadDecription:
        'Lorem ipsum is sample text used for testing purposes',
      downloadSample: 'DOWNLOAD SAMPLE FILE',
      dragAndDrop: 'Drag and Drop your .CSV files',
      fileSpec: '.Only CSV File',
      selectFile: 'Or select .CSV file',
      title: 'IMPORT CONTACTS',
      uploadFile: 'UPLOAD FILE'
    },
    importLeads: {
      agreement: 'Agreement',
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      download: 'DOWNLOAD',
      downloadDecription:
        'Lorem ipsum is sample text used for testing purposes',
      downloadSample: 'DOWNLOAD SAMPLE FILE',
      dragAndDrop: 'Drag and Drop your .CSV files',
      fileSpec: '.Only CSV File',
      selectFile: 'Or select .CSV file',
      title: 'IMPORT LEADS',
      uploadFile: 'UPLOAD FILE'
    },
    importTasks: {
      agreement: 'Agreement',
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      download: 'DOWNLOAD',
      downloadDecription:
        'Lorem ipsum is sample text used for testing purposes',
      downloadSample: 'DOWNLOAD SAMPLE FILE',
      dragAndDrop: 'Drag and Drop your .CSV files',
      fileSpec: '.Only CSV File',
      selectFile: 'Or select .CSV file',
      title: 'IMPORT TASKS',
      uploadFile: 'UPLOAD FILE'
    },
    importTransactions: {
      agreement: 'Agreement',
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      download: 'DOWNLOAD',
      downloadDecription:
        'Lorem ipsum is sample text used for testing purposes',
      downloadSample: 'DOWNLOAD SAMPLE FILE',
      dragAndDrop: 'Drag and Drop your .CSV files',
      fileSpec: '.Only CSV File',
      selectFile: 'Or select .CSV file',
      title: 'IMPORT TRANSACTIONS',
      uploadFile: 'UPLOAD FILE'
    },
    importUsers: {
      agreement: 'Agreement',
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
      download: 'DOWNLOAD',
      downloadDecription:
        'Lorem ipsum is sample text used for testing purposes',
      downloadSample: 'DOWNLOAD SAMPLE FILE',
      dragAndDrop: 'Drag and Drop your .CSV files',
      fileSpec: '.Only CSV File',
      selectFile: 'Or select .CSV file',
      title: 'IMPORT USERS',
      uploadFile: 'UPLOAD FILE'
    },
    kanbanView: {
      actionText: 'Lorem Ipsum is simaply dummay Lorem Ipsum is',
      addUser: 'Add User',
      avatar: `${require('design/icons/users/user_02.png')}`,
      delete: 'Delete',
      export: 'Export',
      import: 'Import',
      sort: 'Sort'
    },
    role: {
      admin: {
        label: 'Admin',
        value: 'ADMIN'
      },
      guest: {
        label: 'Guest',
        value: 'GUEST'
      },
      manager: {
        label: 'Manager',
        value: 'MANAGER'
      },
      regular: {
        label: 'Regular',
        value: 'REGULAR'
      }
    },
    status: {
      active: {
        label: 'Active',
        value: 'Active'
      },
      inactive: {
        label: 'Inactive',
        value: 'Inactive'
      },
      pending: {
        label: 'Pending',
        value: 'Pending'
      }
    }
  },
  weekDays: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],
  welcome: {
    afternoon: 'Good Afternoon',
    evening: 'Good Evening',
    morning: 'Good Morning'
  },
  generateTransaction:{
    instruction:'include instructions that the upload must contain one of the 3 pre-approved forms and that the max PDF page count cannot exceed [50] pages.  I’d also state to please NOT upload HOA documents, Inspection Reports, Title Reports or any other unnecessary documents that are not on the checklist.'
  }
}
/* eslint:enable:quotes */

export default Strings
