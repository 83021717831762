import gql from 'graphql-tag'

import {
  paymentFragment,
  referralFragment,
  transactionFragment,
} from './Fragments'

import {
  documentFragment,
  documentMarkupFragment,
} from '../Document/Fragments'

export const CREATE_TRANSACTION = gql`
  mutation createTransaction(
    $propertyData: PropertyInput!
    $transactionRole: String
    $transactionBoard: String
    $transactionOrder: Int
    $teamTransactionBoard: String
    $teamTransactionOrder: Int
    $office: ID
    $officeTransactionBoard: String
    $officeTransactionOrder: Int
    $tenantTransactionBoard: String
    $tenantTransactionOrder: Int
    $leadId: String
    $contactId: String
    $isActive: Boolean
  ) {
    createTransaction(
      input: {
        propertyInput: $propertyData
        transactionRole: $transactionRole
        transactionBoard: $transactionBoard
        transactionOrder: $transactionOrder
        teamTransactionBoard: $teamTransactionBoard
        teamTransactionOrder: $teamTransactionOrder
        office: $office
        officeTransactionBoard: $officeTransactionBoard
        officeTransactionOrder: $officeTransactionOrder
        tenantTransactionBoard: $tenantTransactionBoard
        tenantTransactionOrder: $tenantTransactionOrder
        leadId: $leadId
        contact: $contactId
        isActive: $isActive
      }
    ) {
      _id
      contact {
        _id
      }
      createdAt
      isActive
      officeTransactionBoard {
        office {
          _id
        }
      }
      owner {
        _id
        firstName
        lastName
        licensedExpiration
        paymentMethod
        userCap {
          commercialCapStatus
          commercialCompletedCap
          commercialTargetCap
          residentialCapStatus
          residentialCompletedCap
          residentialTargetCap
        }
        transactionFeeSetting {
          _id
          name
        }
      }
      propertyId {
        _id
        address {
          _id
          city
          country
          state
          streetName
          streetNumber
          type
          zipCode
        }
        images {
          _id
          name
          order
          url
        }
        mlsId
        price
        subType {
          _id
          name
        }
        type {
          _id
          name
        }
      }
      payment {
        actualCommission
        closingDate
        commissionDue
        expectedCommission
        totalCredits
        totalDebits
        totalIncome
        totalReferrals
      }
      officeTransactionOrder
      progress
      status
      tags
      teamTransactionOrder
      tenantTransactionOrder
      transactionEmail
      transactionId
      transactionRole
      updatedAt
      shareWith {
        _id
        emails {
          type
          value
        }
        firstName
        lastName
        phones {
          type
          value
        }
        profileImage
        role
        status
        type
        userName
      }
    }
  }
`

export const CREATE_TRANSACTION_V2 = gql`
  mutation createTransaction($input: TransactionInput) {
    createTransaction(input: $input) {
      ...TransactionFragment
    }
  }
  ${transactionFragment}
`

export const CREATE_TRANSACTION_VIA_CONCIERGE = gql`
  mutation createTransactionViaConcierge($input: TransactionInput, $file: Upload) {
    createTransactionViaConcierge(input: $input, file: $file)
  }
`

export const UPDATE_TRANSACTION = gql`
  mutation updateTransaction(
    $transactionId: String
    $type: String
    $subType: String
    $transactionRole: String
    $price: Float
    $streetNumber: String
    $streetName: String
    $city: String
    $state: String
    $zipCode: String
    $isActive: Boolean
  ) {
    updateTransaction(
      _id: $transactionId
      input: {
        isActive: $isActive
        propertyId: {
          type: $type
          subType: $subType
          price: $price
          address: {
            streetNumber: $streetNumber
            streetName: $streetName
            city: $city
            state: $state
            zipCode: $zipCode
          }
        }
        transactionRole: $transactionRole
      }
    ) {
      propertyId {
        _id
        type {
          _id
          name
        }
        subType {
          _id
          name
        }
        address {
          _id
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        images {
          _id
          name
          url
          order
        }
        price
        mlsId
      }
      transactionRole
    }
  }
`

export const UPDATE_TRANSACTION_V2 = gql`
  mutation updateTransaction($id: String, $input: TransactionUpdate) {
    updateTransaction(_id: $id, input: $input) {
      _id
      contact {
        _id
      }
      createdAt
      isActive
      officeTransactionBoard {
        office {
          _id
        }
      }
      owner {
        _id
        firstName
        lastName
        licensedExpiration
        paymentMethod
        userCap {
          commercialCapStatus
          commercialCompletedCap
          commercialTargetCap
          residentialCapStatus
          residentialCompletedCap
          residentialTargetCap
        }
        transactionFeeSetting {
          _id
          name
        }
      }
      propertyId {
        _id
        address {
          _id
          city
          country
          state
          streetName
          streetNumber
          type
          zipCode
        }
        images {
          _id
          name
          order
          url
        }
        mlsId
        price
        subType {
          _id
          name
        }
        type {
          _id
          name
        }
      }
      payment {
        _id
        actualCommission
        closingDate
        commissionDue
        expectedCommission
        totalCredits
        totalDebits
        totalIncome
        totalReferrals
      }
      officeTransactionOrder
      progress
      status
      tags
      teamTransactionOrder
      tenantTransactionOrder
      transactionEmail
      transactionId
      transactionRole
      updatedAt
      shareWith {
        _id
        emails {
          type
          value
        }
        firstName
        lastName
        phones {
          type
          value
        }
        profileImage
        role
        status
        type
        userName
      }
    }
  }
`

export const UPDATE_MLSID = gql`
  mutation updateTransaction($transactionId: String, $mlsId: String) {
    updateTransaction(_id: $transactionId, input: { propertyId: { mlsId: $mlsId } }) {
      propertyId {
        _id
        type {
          _id
          name
        }
        subType {
          _id
          name
        }
        address {
          _id
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        images {
          _id
          name
          url
          order
        }
        price
        mlsId
      }
      transactionRole
    }
  }
`

export const DELETE_TRANSACTION = gql`
  mutation deleteTransaction($transactionId: [String]) {
    deleteTransaction(_id: $transactionId)
  }
`

export const SHARE_TRANSACTION = gql`
  mutation sharedTransactionWith(
    $transactionId: String
    $userId: ID
    $firstName: String
    $lastName: String
    $email: String
    $type: String
    $phones: PhoneInput
  ) {
    sharedTransactionWith(
      where: { _id: $transactionId }
      userParams: {
        _id: $userId
        firstName: $firstName
        lastName: $lastName
        userName: $email
        type: $type
        phones: $phones
      }
    ) {
      _id
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
        status
      }
    }
  }
`

export const DELETE_SHARE_WITH_TRANSACTION = gql`
  mutation deleteSharedTransactionWith($transactionId: String, $userId: ID) {
    deleteSharedTransactionWith(where: { _id: $transactionId }, userParams: { _id: $userId }) {
      _id
      shareWith {
        _id
        firstName
        lastName
        profileImage
        type
        emails {
          value
        }
        phones {
          value
        }
        status
      }
    }
  }
`

export const UPDATE_TRANSACTION_DOC_CHECKLIST = gql`
  mutation updateDocTransactionCheckListStatus($docChecklistId: String, $status: String) {
    updateDocTransactionCheckListStatus(where: { _id: $docChecklistId }, input: { status: $status }) {
      _id
      name
      status
      transaction {
        progress
      }
    }
  }
`

export const CREATE_TRANSACTION_DOCUMENTS = gql`
  mutation createTransactionDocument($transactionId: String, $files: [Upload!]) {
    createDocument(input: { transaction: $transactionId }, files: $files) {
      _id
      fileName
      fileType
      url
      thumbnailUrl
      isLocked
      isViewed
      isActive
      rotation
      owner {
        _id
        firstName
        lastName
      }
      transaction {
        _id
        owner {
          _id
        }
      }
    }
  }
`
//TODO: need to remove these
export const EXTRACT_TRANSACTION_DATA=gql`mutation extractTransactionData($jobId: String) {
  extractTransactionData(jobId: $jobId)
  }
  `
export const GET_TEXTRACT_JOB_ID=gql`mutation getTextractJobId($url: String) {
  getTextractJobId(url: $url)
  }
`

export const EXTRACT_AND_CREATE_TRANSACTION=gql`mutation extractAndCreateTransaction($urls: [String], $userId: String, $transactionId: String) {
  extractAndCreateTransaction(urls: $urls, userId: $userId, transactionId: $transactionId)
  }
  `
  
export const CREATE_TRANSACTION_DOCUMENTS_FROM = gql`
  mutation createTransactionDocumentsFrom($docIds: [String], $transactionId: String) {
    createTransactionDocumentsFrom(docIds: $docIds, transactionId: $transactionId) {
      _id
      fileName
      fileType
      url
      thumbnailUrl
      isLocked
      isViewed
      isActive
      rotation
      owner {
        _id
        firstName
        lastName
      }
      transaction {
        _id
        owner {
          _id
        }
      }
    }
  }
`

export const UPDATE_TRANSACTION_DOCUMENT = gql`
  mutation updateTransactionDocument($where: DocumentFilter, $input: DocumentUpdate) {
    updateDocument(where: $where, input: $input) {
      ...DocumentFragment
      viewerMarkup {
        ...DocumentMarkupFragment
      }
    }
  }
  ${documentMarkupFragment}
  ${documentFragment}
`

export const DELETE_TRANSACTION_DOCUMENT = gql`
  mutation deleteTransactionDocument($documentId: String) {
    deleteDocument(_id: $documentId)
  }
`

export const APPLY_WORKFLOW = gql`
  mutation applyWorkflow($workflowId: String!, $transactionId: String, $leadId: String) {
    applyWorkflow(workflowId: $workflowId, transactionId: $transactionId, leadId: $leadId) {
      _id
      type
      name
      description
      dueDate
      status
      sharedWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      createdBy {
        _id
        firstName
        lastName
      }
    }
  }
`

export const IMPORT_TRANSACTIONS = gql`
  mutation importTransactions(
    $file: Upload
    $transactionBoard: String
    $teamTransactionBoard: String
    $officeTransactionBoard: String
    $tenantTransactionBoard: String
  ) {
    importTransaction(
      input: {
        transactionBoard: $transactionBoard
        teamTransactionBoard: $teamTransactionBoard
        officeTransactionBoard: $officeTransactionBoard
        tenantTransactionBoard: $tenantTransactionBoard
      }
      file: $file
    ) {
      _id
      propertyId {
        address {
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        price
        type {
          _id
          name
        }
        subType {
          _id
          name
        }
        images {
          _id
          name
          url
          order
        }
      }
      transactionId
      transactionRole
      status
      progress
      createdAt
      updatedAt
      tenantTransactionOrder
      officeTransactionOrder
      teamTransactionOrder
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      owner {
        _id
        firstName
        lastName
        userCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
          residentialCapStatus
          commercialCapStatus
        }
        licensedExpiration
        transactionFeeSetting {
          _id
          name
        }
        paymentMethod
      }
    }
  }
`

export const UPDATE_TRANSACTION_ALL_DOC_CHECKLIST = gql`
  mutation updateAllDocTransactionCheckListStatus($transactionId: String, $status: String) {
    updateAllDocTransactionCheckListStatus(where: { transaction: $transactionId }, input: { status: $status }) {
      _id
      name
      status
      transaction {
        progress
      }
    }
  }
`

export const UPLOAD_TRANSACTION_PROPERTY_IMAGE = gql`
  mutation uploadPropertyImage($propertyId: String, $file: [Upload!]) {
    uploadPropertyImage(input: { propertyId: $propertyId }, files: $file) {
      _id
      name
      url
      order
    }
  }
`

export const UPLOAD_PROPERTY_IMAGES_VIA_IDX = gql`
  mutation uploadPropertyImagesViaIdx($input: ImageIdxInput) {
    images: uploadPropertyImagesViaIdx(input: $input) {
      _id
      name
      url
      order
    }
  }
`

export const DELETE_TRANSACTION_PROPERTY_IMAGE = gql`
  mutation deletePropertyImage($propertyImageId: String!) {
    deletePropertyImage(_id: $propertyImageId)
  }
`

export const REORDER_TRANSACTION_PROPERTY_IMAGE = gql`
  mutation reorderingImages($images: [ImageFilter]) {
    reorderingImages(input: $images) {
      _id
      name
      url
      order
    }
  }
`

export const ADD_TRANSACTION_MANAGER_NOTES = gql`
  mutation createNote($description: String, $transaction: String) {
    createNote(input: { description: $description, transaction: $transaction }) {
      _id
      description
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const CREATE_TRANSACTION_MESSAGE = gql`
  mutation createMessage($transactionId: String, $message: String) {
    createMessage(input: { message: $message, transaction: $transactionId }) {
      _id
      message
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const UPDATE_TRANSACTION_MANAGER_NOTES = gql`
  mutation updateNote($noteId: String, $description: String) {
    updateNote(where: { _id: $noteId }, input: { description: $description }) {
      _id
      description
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const UPDATE_TRANSACTION_MESSAGE = gql`
  mutation updateMessage($messageId: String, $message: String) {
    updateMessage(where: { _id: $messageId }, input: { message: $message }) {
      _id
      message
      createdBy {
        _id
        firstName
        profileImage
      }
      createdAt
    }
  }
`

export const DELETE_TRANSACTION_MANAGER_NOTES = gql`
  mutation deleteNote($noteId: String) {
    deleteNote(_id: $noteId)
  }
`

export const DELETE_TRANSACTION_MESSAGE = gql`
  mutation deleteMessage($messageId: String) {
    deleteMessage(_id: $messageId)
  }
`

export const UPDATE_TRANSACTION_COMMISSION = gql`
  mutation updateTransactionCommission(
    $commissionId: String!
    $actualCommission: Float
    $expectedCommission: Float
    $closingDate: Date
    $receivedDate: Date
  ) {
    updatePayment(
      _id: $commissionId
      input: {
        actualCommission: $actualCommission
        expectedCommission: $expectedCommission
        closingDate: $closingDate
        receivedDate: $receivedDate
      }
    ) {
      _id
      referrals {
        _id
        type
        amount
        feeObj {
          _id
          name
          fee
          type
        }
        office {
          _id
        }
        user {
          _id
        }
        payment {
          _id
        }
        brokerageName
        brokerageTaxID
        description
        name
        phone
        address {
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
      }
      fees {
        _id
        type
        name
        amount
        key
        feeObj {
          _id
          type
          fee
        }
        isDefault
      }
      transaction {
        _id
        status
        owner {
          _id
          firstName
          lastName
          userCap {
            residentialTargetCap
            commercialTargetCap
            residentialCompletedCap
            commercialCompletedCap
            residentialCapStatus
            commercialCapStatus
          }
          licensedExpiration
          transactionFeeSetting {
            _id
            name
          }
          paymentMethod
          hasTeam
        }
      }
      teamCap{
            residentialTargetCap
            commercialTargetCap
            residentialCompletedCap
            commercialCompletedCap
      }
      expectedCommission
      actualCommission
      totalCredits
      totalIncome
      totalExpenses
      totalDebits
      totalReferrals
      commissionDue
      paidDate
      receivedDate
      closingDate
      isApproved
      isReleased
    }
  }
`

export const CREATE_REFERRAL_FEE = gql`
  mutation createReferralFee($input: ReferralInput) {
    createReferral(input: $input) {
      ...ReferralFragment
      payment {
        ...PaymentFragment
      }
    }
  }
  ${referralFragment}
  ${paymentFragment}
`

export const UPDATE_REFERRAL_FEE = gql`
  mutation updateReferralFee(
    $_id: String
    $input: ReferralInput
  ) {
    updateReferral(
      _id: $_id
      input: $input
    ) {
      ...ReferralFragment
      payment {
        ...PaymentFragment
      }
    }
  }
  ${referralFragment}
  ${paymentFragment}
`

export const DELETE_REFERRAL_FEE = gql`
  mutation deleteReferralFee($referralId: String) {
    deleteReferral(_id: $referralId)
  }
`

export const CREATE_CREDIT_DEBIT_PAYMENT = gql`
  mutation createDebitCreditPayment($input: PaymentDetailInput) {
    createPaymentDetail(input: $input) {
      _id
      type
      name
      amount
      key
      isDefault
      payment {
        _id
        totalDebits
        totalCredits
        totalReferrals
        totalIncome
        totalExpenses
        commissionDue
      }
      feeObj {
        _id
        fee
        type
      }
    }
  }
`

export const UPDATE_CREDIT_DEBIT_PAYMENT = gql`
  mutation updateDebitCreditPayment($id: String, $input: PaymentDetailInput) {
    updatePaymentDetail(_id: $id, input: $input) {
      _id
      type
      name
      amount
      key
      isDefault
      payment {
        _id
        totalDebits
        totalCredits
        totalReferrals
        totalIncome
        totalExpenses
        commissionDue
      }
      feeObj {
        _id
        fee
        type
      }
    }
  }
`

export const DELETE_CREDIT_DEBIT_PAYMENT = gql`
  mutation deleteCreditDebitPayment($feeId: String) {
    deletePaymentDetail(_id: $feeId)
  }
`

export const UPDATE_USER_LICENSED_EXPIRATION = gql`
  mutation updateUser($userId: ID, $licensedExpiration: Date) {
    updateUser(where: { _id: $userId }, input: { licensedExpiration: $licensedExpiration }) {
      firstName
      licensedExpiration
      paymentMethod
    }
  }
`

export const UPDATE_USER_PAYMENT_METHOD = gql`
  mutation updateUser($userId: ID, $paymentMethod: String) {
    updateUser(where: { _id: $userId }, input: { paymentMethod: $paymentMethod }) {
      firstName
      licensedExpiration
      paymentMethod
    }
  }
`

export const UPDATE_USER_COMMISSION_PLAN = gql`
  mutation updateUser($userId: ID, $transactionFeeSetting: String) {
    updateUser(where: { _id: $userId }, input: { transactionFeeSetting: $transactionFeeSetting }) {
      firstName
      licensedExpiration
      paymentMethod
    }
  }
`

export const READ_MESSAGES = gql`
  mutation readMessages($transactionId: String, $userId: String) {
    readMessages(where: { transaction: $transactionId }, input: { readBy: $userId }) {
      _id
      message
    }
  }
`

export const PAYMENT_APPROVED = gql`
  mutation updatePayment($paymentId: String!, $isApproved: Boolean) {
    updatePayment(_id: $paymentId, input: { isApproved: $isApproved }) {
      isApproved
      isReleased
    }
  }
`

export const PAYMENT_RELEASED = gql`
  mutation updatePayment($paymentId: String!, $isReleased: Boolean) {
    updatePayment(_id: $paymentId, input: { isReleased: $isReleased }) {
      isApproved
      isReleased
    }
  }
`

export const CREATE_TRANSACTION_DOC_CHECKLIST = gql`
  mutation createTransactionDocChecklist($checklistId: String, $transactionId: String!) {
    applyTransactionChecklist(where: { _id: $checklistId }, transactionId: $transactionId) {
      _id
      name
      status
    }
  }
`

export const CHANGE_TRANSACTION_OWNER = gql`
  mutation changeTransactionOwner($transactionId: String!, $userId: String) {
    changeOwnership(transactionId: $transactionId, userId: $userId) {
      _id
      propertyId {
        _id
        type {
          _id
          name
        }
        subType {
          _id
          name
        }
        address {
          _id
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        images {
          _id
          name
          url
          order
        }
        price
      }
      transactionId
      transactionRole
      status
      tags
      progress
      createdAt
      updatedAt
      owner {
        _id
        firstName
        lastName
        userCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
          residentialCapStatus
          commercialCapStatus
        }
        licensedExpiration
        transactionFeeSetting {
          _id
          name
        }
        paymentMethod
      }
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      isActive
    }
  }
`

export const APPLY_TRANSACTION = gql`
  mutation applyTransaction($accountId: String, $transactionId: String, $applyStatus: Boolean) {
    applyUserCreditDebit(where: { _id: $accountId }, transactionId: $transactionId, apply: $applyStatus) {
      _id
      fees {
        name
        amount
      }
      transaction {
        _id
        status
        owner {
          _id
          firstName
          lastName
          userCap {
            residentialTargetCap
            commercialTargetCap
            residentialCompletedCap
            commercialCompletedCap
            residentialCapStatus
            commercialCapStatus
          }
          licensedExpiration
          transactionFeeSetting {
            _id
            name
          }
          paymentMethod
        }
      }
      expectedCommission
      actualCommission
      totalCredits
      totalIncome
      totalExpenses
      totalDebits
      totalReferrals
      commissionDue
      paidDate
      receivedDate
      closingDate
      isApproved
      isReleased
      referrals {
        _id
        type
        amount
        feeObj {
          _id
          name
          fee
          type
        }
        office {
          _id
        }
        user {
          _id
        }
        payment {
          _id
        }
        brokerageName
        brokerageTaxID
        description
        name
        phone
        address {
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
      }
      fees {
        _id
        type
        name
        amount
        key
        feeObj {
          _id
          type
          fee
        }
        isDefault
      }
    }
  }
`

export const UPDATE_TRANSACTION_ORDER = gql`
  mutation updateTransactionOrder(
    $transactionBoard: String
    $teamTransactionBoard: String
    $officeTransactionBoard: String
    $tenantTransactionBoard: String
    $cardId: String
    $transactionSourceBoardOrder: Int
    $teamTransactionSourceBoardOrder: Int
    $officeTransactionSourceBoardOrder: Int
    $tenantTransactionSourceBoardOrder: Int
    $transactionDestinationBoardOrder: Int
    $teamTransactionDestinationBoardOrder: Int
    $officeTransactionDestinationBoardOrder: Int
    $tenantTransactionDestinationBoardOrder: Int
  ) {
    updateTransactionOrder(
      where: {
        _id: $cardId
        transactionBoard: $transactionBoard
        teamTransactionBoard: $teamTransactionBoard
        officeTransactionBoard: $officeTransactionBoard
        tenantTransactionBoard: $tenantTransactionBoard
        transactionOrder: $transactionSourceBoardOrder
        teamTransactionOrder: $teamTransactionSourceBoardOrder
        officeTransactionOrder: $officeTransactionSourceBoardOrder
        tenantTransactionOrder: $tenantTransactionSourceBoardOrder
      }
      input: {
        transactionOrder: $transactionDestinationBoardOrder
        teamTransactionOrder: $teamTransactionDestinationBoardOrder
        officeTransactionOrder: $officeTransactionDestinationBoardOrder
        tenantTransactionOrder: $tenantTransactionDestinationBoardOrder
      }
    ) {
      _id
      propertyId {
        _id
        type {
          _id
          name
        }
        subType {
          _id
          name
        }
        address {
          _id
          type
          streetNumber
          streetName
          city
          state
          country
          zipCode
        }
        images {
          _id
          name
          url
          order
        }
        price
      }
      transactionId
      transactionRole
      status
      tags
      progress
      createdAt
      updatedAt
      owner {
        _id
        firstName
        lastName
        userCap {
          residentialTargetCap
          commercialTargetCap
          residentialCompletedCap
          commercialCompletedCap
          residentialCapStatus
          commercialCapStatus
        }
        licensedExpiration
        transactionFeeSetting {
          _id
          name
        }
        paymentMethod
      }
      shareWith {
        _id
        firstName
        lastName
        userName
        role
        status
        type
        phones {
          type
          value
        }
        emails {
          type
          value
        }
        profileImage
      }
      isActive
    }
  }
`

export const CREATE_FLYER_TEMPLATE = gql`
  mutation createFlyerTemplate(
    $transactionId: ID
    $title: String
    $category: ID
    $subCategory: ID
    $data: JSON
    $owner: ID
  ) {
    createFlyerTemplate(
      input: {
        transaction: $transactionId
        owner: $owner
        title: $title
        data: $data
        category: $category
        subCategory: $subCategory
        type: "FREE"
      }
    ) {
      _id
      title
      data
      type
      owner {
        _id
        firstName
        _id
      }
    }
  }
`

export const UPDATE_FLYER_TEMPLATE = gql`
  mutation updateFlyerTemplate($templateId: ID!, $data: JSON) {
    updateFlyerTemplate(_id: $templateId, input: { data: $data }) {
      _id
      title
      type
    }
  }
`
