// Import Components
import client from 'queries/apollo'

import { PDFDocumentType } from 'shared/PDFViewer/Types'
import { ShareWith, TransactionList, UserType } from 'store/Transactions/Types'

// Import Graphql Mutations
import { CREATE_TASK, DELETE_TASK, UPDATE_BULK_TASKS, UPDATE_TASK } from 'queries/graphql/Tasks/Mutations'
import { GET_TASK } from 'queries/graphql/Tasks/Queries'
import {
  ADD_TRANSACTION_MANAGER_NOTES,
  APPLY_TRANSACTION,
  APPLY_WORKFLOW,
  CHANGE_TRANSACTION_OWNER,
  CREATE_CREDIT_DEBIT_PAYMENT,
  CREATE_FLYER_TEMPLATE,
  CREATE_REFERRAL_FEE,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_DOC_CHECKLIST,
  CREATE_TRANSACTION_DOCUMENTS,
  CREATE_TRANSACTION_MESSAGE,
  DELETE_CREDIT_DEBIT_PAYMENT,
  DELETE_REFERRAL_FEE,
  DELETE_SHARE_WITH_TRANSACTION,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_DOCUMENT,
  DELETE_TRANSACTION_MANAGER_NOTES,
  DELETE_TRANSACTION_MESSAGE,
  DELETE_TRANSACTION_PROPERTY_IMAGE,
  EXTRACT_AND_CREATE_TRANSACTION,
  EXTRACT_TRANSACTION_DATA,
  GET_TEXTRACT_JOB_ID,
  IMPORT_TRANSACTIONS,
  PAYMENT_APPROVED,
  PAYMENT_RELEASED,
  READ_MESSAGES,
  REORDER_TRANSACTION_PROPERTY_IMAGE,
  SHARE_TRANSACTION,
  UPDATE_CREDIT_DEBIT_PAYMENT,
  UPDATE_FLYER_TEMPLATE,
  UPDATE_MLSID,
  UPDATE_REFERRAL_FEE,
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_ALL_DOC_CHECKLIST,
  UPDATE_TRANSACTION_COMMISSION,
  UPDATE_TRANSACTION_DOC_CHECKLIST,
  UPDATE_TRANSACTION_DOCUMENT,
  UPDATE_TRANSACTION_MANAGER_NOTES,
  UPDATE_TRANSACTION_MESSAGE,
  UPDATE_TRANSACTION_ORDER,
  UPDATE_USER_COMMISSION_PLAN,
  UPDATE_USER_LICENSED_EXPIRATION,
  UPDATE_USER_PAYMENT_METHOD,
  UPLOAD_TRANSACTION_PROPERTY_IMAGE
} from 'queries/graphql/Transactions/Mutation'

import { MOVE_TRANSACTION, UPDATE_TRANSACTION_BOARD_ORDER } from 'queries/graphql/TransactionBoard/Mutations'
import { GET_TRANSACTION_DETAILS } from 'queries/graphql/Transactions/Queries'
import { UPDATE_USER } from 'queries/graphql/Users/Mutation'

import { getMyTaskBoards } from 'app/Tasks/TaskQueries'

export const createTransaction = async (
  newTransaction: TransactionList | any,
  index: string,
  addBoardId: string,
  show: boolean,
  user: UserType,
  edit: boolean,
  leadId?: string,
  contactId?: string
) => {
  const { type, subType, transactionRole, price, streetNumber, streetName, city, state, zipCode } = newTransaction
  const property = {
    address: {
      city,
      state,
      streetName: streetName.trim(),
      streetNumber: streetNumber.trim(),
      zipCode
    },
    price: parseFloat(price),
    subType,
    type
  }
  if (show) {
    return await client.mutate({
      mutation: CREATE_TRANSACTION,
      variables: {
        officeTransactionBoard: user.role === 'MANAGER' ? addBoardId : undefined,
        officeTransactionOrder: user.role === 'MANAGER' ? parseInt(index) : undefined,
        propertyData: property,
        teamTransactionBoard: user.role === 'TEAM_LEADER' ? addBoardId : undefined,
        teamTransactionOrder: user.role === 'TEAM_LEADER' ? parseInt(index) : undefined,
        tenantTransactionBoard: user.role === 'ADMIN' ? addBoardId : undefined,
        tenantTransactionOrder: user.role === 'ADMIN' ? parseInt(index) : undefined,
        transactionBoard: user.role === 'AGENT' ? addBoardId : undefined,
        transactionOrder: user.role === 'AGENT' ? parseInt(index) : undefined,
        transactionRole
      }
    })
  } else {
    if (edit) {
      return await client.mutate({
        mutation: CREATE_TRANSACTION,
        variables: {
          officeTransactionBoard: user.role === 'MANAGER' ? addBoardId : undefined,
          propertyData: property,
          teamTransactionBoard: user.role === 'TEAM_LEADER' ? addBoardId : undefined,
          tenantTransactionBoard: user.role === 'ADMIN' ? addBoardId : undefined,
          transactionBoard: user.role === 'AGENT' ? addBoardId : undefined,
          transactionRole
        }
      })
    } else {
      return await client.mutate({
        mutation: CREATE_TRANSACTION,
        variables: {
          contactId,
          leadId: leadId !== '' ? leadId : undefined,
          officeTransactionBoard: user.role === 'MANAGER' ? addBoardId : undefined,
          propertyData: property,
          teamTransactionBoard: user.role === 'TEAM_LEADER' ? addBoardId : undefined,
          tenantTransactionBoard: user.role === 'ADMIN' ? addBoardId : undefined,
          transactionBoard: user.role === 'AGENT' ? addBoardId : undefined,
          transactionRole
        }
      })
    }
  }
}

/* TODO: deprecate this version of UPDATE_TRANSACTION and replace with UPDATE_TRANSACTION_V2 (this is the last one) */
export const updateTransaction = async (transactionId: string, transaction: TransactionList | any) => {
  const { type, subType, transactionRole, price, streetNumber, streetName, city, state, zipCode } = transaction
  await client.mutate({
    mutation: UPDATE_TRANSACTION,
    refetchQueries: [{ query: GET_TRANSACTION_DETAILS, variables: { transactionId } }],
    variables: {
      city,
      price: parseFloat(price),
      state,
      streetName: streetName.trim(),
      streetNumber: streetNumber.trim(),
      subType,
      transactionId,
      transactionRole,
      type,
      zipCode
    }
  })

  const response = client.readQuery({
    query: GET_TRANSACTION_DETAILS,
    variables: { transactionId }
  })

  return response.getTransactions[0]
}

export const updateMLSID = async (transactionId: string, mlsId: string) => {
  const result: any = await client.mutate({
    mutation: UPDATE_MLSID,
    variables: { transactionId, mlsId }
  })
  return result.data.updateTransaction
}

export const deleteTransaction = async (transactionListId: string[]) => {
  await client.mutate({
    mutation: DELETE_TRANSACTION,
    variables: { transactionId: transactionListId }
  })
}

export const importTransactions = async (boardId: string, file: any, user: any) => {
  const res = await client.mutate({
    mutation: IMPORT_TRANSACTIONS,
    variables: {
      file,
      officeTransactionBoard: user.role === 'MANAGER' ? boardId : undefined,
      teamTransactionBoard: user.role === 'TEAM_LEADER' ? boardId : undefined,
      tenantTransactionBoard: user.role === 'ADMIN' ? boardId : undefined,
      transactionBoard: user.role === 'AGENT' ? boardId : undefined
    }
  })
  return res.data.importTransaction
}

export const shareTransactionWithUser = async (transactionId: string, shareWithObject: ShareWith) => {
  const response = await client.mutate({
    mutation: SHARE_TRANSACTION,
    variables: { transactionId: transactionId, ...shareWithObject }
  })
  return response.data.sharedTransactionWith.shareWith
}

export const deleteShareTransactionWithUser = async (userId: string, transactionId: string) => {
  const response = await client.mutate({
    mutation: DELETE_SHARE_WITH_TRANSACTION,
    variables: { transactionId: transactionId, userId: userId }
  })
  return response.data.deleteSharedTransactionWith.shareWith
}

export const createTransactionTask = async (
  task: any,
  transactionId: string,
  edit: boolean,
  ownerId: string,
  loggedUser: any
) => {
  const filter = false
  const { type, name, description, dueDate, sharedWith, _id, taskTime } = task
  const taskBoards = await getMyTaskBoards(loggedUser._id)
  const newBoardId = taskBoards.filter((board: any) => {
    return board.name === 'New Task'
  })
  if (!edit) {
    return await client.mutate({
      mutation: CREATE_TASK,
      refetchQueries: [
        {
          query: GET_TASK,
          variables: { transactionId }
        }
      ],
      variables: {
        assignee: ownerId,
        description: description.trim(),
        dueDate: dueDate,
        name: name.trim(),
        officeTaskBoard: filter !== false && loggedUser.role === 'MANAGER' ? newBoardId[0]._id : undefined,
        sharedWith,
        taskBoard: filter === false || loggedUser.role === 'AGENT' ? newBoardId[0]._id : undefined,
        taskTime,
        teamTaskBoard: filter !== false && loggedUser.role === 'TEAM_LEADER' ? newBoardId[0]._id : undefined,
        tenantTaskBoard: filter !== false && loggedUser.role === 'ADMIN' ? newBoardId[0]._id : undefined,
        transactionId,
        type
      }
    })
  } else {
    return await client.mutate({
      mutation: UPDATE_TASK,
      refetchQueries: [
        {
          query: GET_TASK,
          variables: { transactionId }
        }
      ],
      variables: {
        description,
        dueDate,
        name,
        officeTaskBoard: filter !== false && loggedUser.role === 'MANAGER' ? newBoardId._id : undefined,
        sharedWith,
        taskBoard: filter === false || loggedUser.role === 'AGENT' ? newBoardId._id : undefined,
        taskId: _id,
        taskTime,
        teamTaskBoard: filter !== false && loggedUser.role === 'TEAM_LEADER' ? newBoardId._id : undefined,
        tenantTaskBoard: filter !== false && loggedUser.role === 'ADMIN' ? newBoardId._id : undefined,
        type
      }
    })
  }
}

export const deleteTransactionTask = async (taskId: string, transactionId: string) => {
  const taskIds = []
  taskIds.push(taskId)
  await client.mutate({
    mutation: DELETE_TASK,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { transactionId }
      }
    ],
    variables: { taskId: taskIds }
  })
}

export const updateTask = async (
  taskId: string,
  status: string | undefined,
  assigneeId: string | undefined,
  transactionId: string
) => {
  const response = await client.mutate({
    mutation: UPDATE_TASK,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { transactionId }
      }
    ],
    variables: {
      assigneeId,
      status,
      taskId
    }
  })

  return response.data.updateTask
}

export const ChangeTransactionTaskToggleCheckBox = async (checked: boolean, transactionId: string) => {
  const data: any = await client.query({
    fetchPolicy: 'network-only',
    query: GET_TASK,
    variables: { transactionId }
  })
  const newData = data.data.getTasks.map((list: any) => {
    return {
      ...list,
      checked: !checked
    }
  })

  client.writeQuery({
    data: { getTasks: newData },
    query: GET_TASK,
    variables: { transactionId }
  })
  return newData
}

export const ToggleTransactionTaskSingleCheckBox = async (id: string, transactionId: string) => {
  const data: any = await client.query({
    query: GET_TASK,
    variables: { transactionId }
  })

  const newData = data.data.getTasks.map((list: any) => {
    return {
      ...list,
      checked: list._id === id ? !list.checked : list.checked
    }
  })

  client.writeQuery({
    data: { getTasks: newData },
    query: GET_TASK,
    variables: { transactionId }
  })
  return newData
}

export const ToggleTransactionCheckBox = async (transactionId: string) => {
  const data: any = await client.query({
    query: GET_TASK,
    variables: { transactionId }
  })
  const newData = data.data.getTasks.map((list: any) => {
    return {
      ...list,
      checked: false
    }
  })
  client.writeQuery({
    data: { getTasks: newData },
    query: GET_TASK,
    variables: { transactionId }
  })
  return newData
}

export const deleteTasks = async (taskIds: string, transactionId: string) => {
  await client.mutate({
    mutation: DELETE_TASK,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { transactionId }
      }
    ],
    variables: { taskId: taskIds }
  })
}

export const updateTransactionDocumentChecklists = async (docChecklistId: string, status: string) => {
  const response = await client.mutate({
    mutation: UPDATE_TRANSACTION_DOC_CHECKLIST,
    variables: { docChecklistId, status }
  })

  return response.data.updateDocTransactionCheckListStatus
}

export const createTransactionDocuments = async (file: any, transactionId: string) => {
  const response = await client.mutate({
    mutation: CREATE_TRANSACTION_DOCUMENTS,
    variables: { transactionId, files: file }
  })

  return response.data.createDocument
}
//TODO: need to remove these
export const extractTransactionData=async(jobId:string)=>{
  if(!jobId){return}
  const response = await client.mutate({
    mutation: EXTRACT_TRANSACTION_DATA,
    variables: { jobId }
  })
  return response.data
}
//TODO: need to remove these
export const getTextractJobId=async(url:string)=>{
  if(!url){return}
  const response = await client.mutate({
    mutation: GET_TEXTRACT_JOB_ID,
    variables: { url }
  })
  return response.data
}

export const extractAndCreateTransaction=async (urls:string[],id:string,transactionId:string)=>{
  if(!urls){return}
  const response = await client.mutate({
    mutation: EXTRACT_AND_CREATE_TRANSACTION,
    variables: { urls ,userId:id,transactionId:transactionId}
  })
  return response.data
}

export const updateTransactionDocument = async (_id: string, input: Partial<PDFDocumentType>) => {
  const response = await client.mutate({
    mutation: UPDATE_TRANSACTION_DOCUMENT,
    variables: {
      input,
      where: { _id },
    },
  })

  return response.data.updateDocument
}

export const deleteTransactionDocument = async (documentId: string) => {
  const response = await client.mutate({
    mutation: DELETE_TRANSACTION_DOCUMENT,
    variables: { documentId }
  })

  return response.data.deleteDocument
}

export const applyWorkflow = async (workflowId: string, transactionId: string) => {
  return await client.mutate({
    mutation: APPLY_WORKFLOW,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { transactionId }
      }
    ],
    variables: { workflowId, transactionId }
  })
}

export const updateTransactionAllDocChecklist = async (transactionId: string, status: string) => {
  const response = await client.mutate({
    mutation: UPDATE_TRANSACTION_ALL_DOC_CHECKLIST,
    variables: { transactionId, status }
  })

  return response.data.updateAllDocTransactionCheckListStatus
}

export const uploadTransactionPropertyImage = async (propertyId: string, file: any) => {
  const response = await client.mutate({
    mutation: UPLOAD_TRANSACTION_PROPERTY_IMAGE,
    variables: { propertyId, file }
  })

  return response.data.uploadPropertyImage[0]
}

export const uploadTransactionPropertyImages = async (propertyId: string, files: any) => {
  const response = await client.mutate({
    mutation: UPLOAD_TRANSACTION_PROPERTY_IMAGE,
    variables: { propertyId, file: files }
  })

  return response.data.uploadPropertyImage
}

export const deleteTransactionPropertyImage = async (propertyImageId: string) => {
  const response = await client.mutate({
    mutation: DELETE_TRANSACTION_PROPERTY_IMAGE,
    variables: { propertyImageId }
  })

  return response.data.deletePropertyImage
}

export const reorderTransactionPropertyImage = async (propertyImages: any) => {
  const response = await client.mutate({
    mutation: REORDER_TRANSACTION_PROPERTY_IMAGE,
    variables: { images: propertyImages }
  })

  return response.data.reorderingImages
}

export const addManagerNote = async (description: string, transaction: string) => {
  const response = await client.mutate({
    mutation: ADD_TRANSACTION_MANAGER_NOTES,
    variables: { description, transaction }
  })

  return response.data.createNote
}

export const updateManagerNote = async (noteId: string, description: string) => {
  const response = await client.mutate({
    mutation: UPDATE_TRANSACTION_MANAGER_NOTES,
    variables: { noteId, description }
  })

  return response.data.updateNote
}

export const deleteManagerNotes = async (noteId: string) => {
  const response = await client.mutate({
    mutation: DELETE_TRANSACTION_MANAGER_NOTES,
    variables: { noteId }
  })

  return response.data.deleteNote
}

export const updateTransactionCommission = async (data: any) => {
  const { _id, expectedCommission, actualCommission, closingDate, receivedDate } = data
  const response = await client.mutate({
    mutation: UPDATE_TRANSACTION_COMMISSION,
    variables: {
      actualCommission: actualCommission ? parseFloat(actualCommission) : undefined,
      closingDate: closingDate ? closingDate : undefined,
      commissionId: _id,
      expectedCommission: expectedCommission ? parseFloat(expectedCommission) : undefined,
      receivedDate: receivedDate ? receivedDate : undefined
    }
  })

  return response.data.updatePayment
}

export const addMessage = async (transactionId: string, message: string) => {
  const response = await client.mutate({
    mutation: CREATE_TRANSACTION_MESSAGE,
    variables: { message, transactionId }
  })

  return response.data.createMessage
}

export const updateMessage = async (messageId: string, message: string) => {
  const response = await client.mutate({
    mutation: UPDATE_TRANSACTION_MESSAGE,
    variables: { messageId, message }
  })

  return response.data.updateMessage
}

export const deleteMessages = async (messageId: string) => {
  const response = await client.mutate({
    mutation: DELETE_TRANSACTION_MESSAGE,
    variables: { messageId }
  })

  return response.data.deleteMessage
}

export const createTransactionReferralFee = async (paymentId: string, data: any) => {
  const input:any = {
    payment: paymentId,
    ...data,
    feeObj: {
      fee: parseFloat(data.feeObj.fee),
      type: data.feeObj.type,
    }
  }
  const response = await client.mutate({
    mutation: CREATE_REFERRAL_FEE,
    variables: { input }
  })

  return response.data.createReferral
}

export const updateTransactionReferralFee = async (referralId: string, data: any) => {
  const input: any = {
    ...data,
    feeObj: {
      fee: parseFloat(data.feeObj.fee),
      type: data.feeObj.type
    }
  }

  if (input._id) {
    delete input._id
  }
  const response = await client.mutate({
    mutation: UPDATE_REFERRAL_FEE,
    variables: {
      _id: referralId,
      input,
    }
  })

  return response.data.updateReferral
}

export const deleteTransactionReferralFee = async (referralId: string) => {
  const response = await client.mutate({
    mutation: DELETE_REFERRAL_FEE,
    variables: { referralId }
  })

  return response.data.deleteReferral
}

export const createTransactionFee = async (input: any) => {
  if (input.feeObj.fee) {
    input.feeObj.fee = parseFloat(input.feeObj.fee)
  }
  const response = await client.mutate({
    mutation: CREATE_CREDIT_DEBIT_PAYMENT,
    variables: { input }
  })

  return response.data.createPaymentDetail
}

export const updateTransactionFee = async (id: string, input: any) => {
  if (input.feeObj.fee) {
    input.feeObj.fee = parseFloat(input.feeObj.fee)
  }
  const response = await client.mutate({
    mutation: UPDATE_CREDIT_DEBIT_PAYMENT,
    variables: { id, input }
  })

  return response.data.updatePaymentDetail
}

export const deleteTransactionFee = async (feeId: string) => {
  const response = await client.mutate({
    mutation: DELETE_CREDIT_DEBIT_PAYMENT,
    variables: { feeId }
  })

  return response.data.deletePaymentDetail
}

export const updateUserLicensedExpiration = async (userID: string, licensedExpiration: string) => {
  const response = await client.mutate({
    mutation: UPDATE_USER_LICENSED_EXPIRATION,
    variables: { userID, licensedExpiration }
  })

  return response.data.updateUser
}

export const updateUserPaymentMethod = async (userID: string, paymentMethod: string) => {
  const response = await client.mutate({
    mutation: UPDATE_USER_PAYMENT_METHOD,
    variables: { userID, paymentMethod }
  })

  return response.data.updateUser
}

export const updateUserCommissionPlan = async (userID: string, transactionFeeSetting: string) => {
  const response = await client.mutate({
    mutation: UPDATE_USER_COMMISSION_PLAN,
    variables: { userID, transactionFeeSetting }
  })

  return response.data.updateUser
}

export const readMessages = async (transactionId: string, userId: string) => {
  const response = await client.mutate({
    mutation: READ_MESSAGES,
    variables: { transactionId, userId }
  })

  return response.data.readMessages
}

export const paymentApproved = async (paymentId: string, isApproved: boolean) => {
  const response = await client.mutate({
    mutation: PAYMENT_APPROVED,
    variables: { paymentId, isApproved }
  })

  return response.data.updatePayment
}

export const paymentReleased = async (paymentId: string, isReleased: boolean) => {
  const response = await client.mutate({
    mutation: PAYMENT_RELEASED,
    variables: { paymentId, isReleased }
  })

  return response.data.updatePayment
}

export const createChecklist = async (checklistId: string, transactionId: string) => {
  const response = await client.mutate({
    mutation: CREATE_TRANSACTION_DOC_CHECKLIST,
    variables: { checklistId, transactionId }
  })

  return response.data.applyTransactionChecklist
}

export const changeTransactionOwnership = async (userId: string, transactionId: string) => {
  const response = await client.mutate({
    mutation: CHANGE_TRANSACTION_OWNER,
    variables: { transactionId, userId }
  })

  return response.data.changeOwnership
}

export const editInvitee = async (userObj: any) => {
  const { firstName, lastName, phone, _id, type } = userObj
  const response = await client.mutate({
    mutation: UPDATE_USER,
    variables: {
      _id,
      firstName,
      lastName,
      phone: {
        type: 'Mobile',
        value: phone
      },
      type
    }
  })

  return response.data.updateUser
}

export const applyTransaction = async (accountId: string, transactionId: string, applyStatus: boolean) => {
  const response = await client.mutate({
    mutation: APPLY_TRANSACTION,
    variables: { accountId, transactionId, applyStatus }
  })

  return response.data.applyUserCreditDebit
}

export const updateBulkTask = async (taskIds: string, status: string, transactionId: string) => {
  const response = await client.mutate({
    mutation: UPDATE_BULK_TASKS,
    refetchQueries: [
      {
        query: GET_TASK,
        variables: { transactionId }
      }
    ],
    variables: { taskIds, status }
  })

  return response.data.updateBulkTasks
}

export const moveTransaction = async (
  transactionId: string,
  transactionBoardId: string,
  destinationIndex: number,
  user: any
) => {
  const response = await client.mutate({
    mutation: MOVE_TRANSACTION,
    variables: {
      officeTransactionBoard: user.role === 'MANAGER' ? transactionBoardId : undefined,
      officeTransactionOrder: user.role === 'MANAGER' ? destinationIndex : undefined,
      teamTransactionBoard: user.role === 'TEAM_LEADER' ? transactionBoardId : undefined,
      teamTransactionOrder: user.role === 'TEAM_LEADER' ? destinationIndex : undefined,
      tenantTransactionBoard: user.role === 'ADMIN' ? transactionBoardId : undefined,
      tenantTransactionOrder: user.role === 'ADMIN' ? destinationIndex : undefined,
      transactionBoard: user.role === 'AGENT' ? transactionBoardId : undefined,
      transactionId,
      transactionOrder: user.role === 'AGENT' ? destinationIndex : undefined
    }
  })
  return response.data.transferTransaction
}

export const reorderCard = async (
  transactionBoardId: string,
  cardId: string,
  sourceIndex: number,
  destinationIndex: number,
  user: UserType
) => {
  client.mutate({
    mutation: UPDATE_TRANSACTION_ORDER,
    variables: {
      cardId,
      destinationIndex,
      officeTransactionBoard: user.role === 'MANAGER' ? transactionBoardId : undefined,
      officeTransactionDestinationBoardOrder: user.role === 'MANAGER' ? destinationIndex : undefined,
      officeTransactionSourceBoardOrder: user.role === 'MANAGER' ? sourceIndex : undefined,
      teamTransactionBoard: user.role === 'TEAM_LEADER' ? transactionBoardId : undefined,
      teamTransactionDestinationBoardOrder: user.role === 'TEAM_LEADER' ? destinationIndex : undefined,
      teamTransactionSourceBoardOrder: user.role === 'TEAM_LEADER' ? sourceIndex : undefined,
      tenantTransactionBoard: user.role === 'ADMIN' ? transactionBoardId : undefined,
      tenantTransactionDestinationBoardOrder: user.role === 'ADMIN' ? destinationIndex : undefined,
      tenantTransactionSourceBoardOrder: user.role === 'ADMIN' ? sourceIndex : undefined,
      transactionBoard: user.role === 'AGENT' ? transactionBoardId : undefined,
      transactionDestinationBoardOrder: user.role === 'AGENT' ? destinationIndex : undefined,
      transactionSourceBoardOrder: user.role === 'AGENT' ? sourceIndex : undefined
    }
  })
}

export const reorderLane = async (boardId: string, sourceIndex: number, destinationIndex: number) => {
  await client.mutate({
    mutation: UPDATE_TRANSACTION_BOARD_ORDER,
    variables: {
      boardId,
      destinationIndex,
      sourceIndex
    }
  })
}

export const createFlyerTemplate = async (
  transactionId: string,
  userId: string,
  title: string,
  category: string,
  subCategory: string,
  data: JSON
) => {
  const response = await client.mutate({
    mutation: CREATE_FLYER_TEMPLATE,
    variables: { transactionId, userId, title, category, subCategory, data }
  })

  return response.data.createFlyerTemplate
}

export const updateFlyerTemplate = async (templateId: string, data: JSON) => {
  const response = await client.mutate({
    mutation: UPDATE_FLYER_TEMPLATE,
    variables: { templateId, data }
  })

  return response.data.updateFlyerTemplate
}
