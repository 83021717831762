import * as React from 'react'
import Scrollable from 'shared/Scrollable'
import Transition from 'shared/Transition'
import {
    BtnWrapper,
    Container,
    Header,
    Image,
    Section,
    StyledDescription,
    Submit,
} from '../TransactionForm/Styled'

import {
    GRAPHIC,
    ModeEnum
} from '../TransactionForm/Types'
import { Strings } from 'utils'
import { extractAndCreateTransaction } from 'app/Transactions/Dashboard/TransactionMutations'
import Toast from 'shared/Toast'

interface Props {
  mode: ModeEnum
  fileLoading:boolean
  fileUrls:string[]
  user: any
  transactionId:string
  onComplete:Function
}

interface State {
isReady: boolean
isLoading:boolean
}

class GenerateTransaction extends React.Component<Props, State> {
  public state = {
    isReady: false,
    isLoading:false
  }

  public componentDidMount = async () => {
  
  }

  public componentDidUpdate = async (prev: Props) => {
 
  }

  public handleGenerateClick = async () => {
    const {fileUrls,user,transactionId,onComplete}=this.props
    try {
        this.setState({isLoading:true})
       const res=await extractAndCreateTransaction(fileUrls,user._id,transactionId)
       if(res?.extractAndCreateTransaction){
        onComplete()
       }else if(res?.error){
        Toast({ message: res.error?.message, type: 'error' })
       }
       this.setState({isLoading:false})
    } catch (error) {
        this.setState({isLoading:false})
        Toast({ message: error?.message, type: 'error' })
    }
  }

  public render = () => {
    const {
      mode,
      fileLoading,
      fileUrls
    } = this.props

    const {  isLoading } = this.state

    return (
      <Transition on={true}>
        <Container className="rp-transaction-form" height={100}>
          <Scrollable>
            <Section height={100} padding={'0 1em'}>
                <Header width={100}>
                  <Image
                    src={GRAPHIC[mode] || GRAPHIC.ManualAdd}
                    max={mode === ModeEnum.Idx ? 180 : 300}
                  />
                </Header>
                <BtnWrapper></BtnWrapper>
                    <Submit
                        onClick={this.handleGenerateClick}
                        content={'Generate'}
                        disabled={fileLoading||fileUrls.length===0||isLoading}
                            />
                    
                <StyledDescription>
                    {Strings.generateTransaction.instruction}
                </StyledDescription>
            </Section>
          </Scrollable>
        </Container>
      </Transition>
    )
  }
}

export default GenerateTransaction
