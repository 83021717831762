import * as React from 'react'

import { Container, Content, Grip } from './Styled'

interface Props {
  className?: string
  closeModal: () => void
  content: React.ReactNode
  height?: number
  maximum?: boolean
  minWidth?: number
  vh?: number
  vw?: number
  width?: number
  heightAuto?:boolean
}

interface State {
  isMouseDown: boolean
  left: number
  top: number
  x: number
  y: number
}

class Modal extends React.Component<Props, State> {
  public state = {
    isMouseDown: false,
    left: 0,
    top: 0,
    x: 0,
    y: 0
  }

  public render() {
    const {
      className,
      content,
      height,
      maximum,
      minWidth,
      vh,
      vw,
      width,
      heightAuto
    } = this.props

    const { isMouseDown, left, top } = this.state

    return (
      <Container
        className={`${className || ''}`}
        open={true}
        dimmer="inverted"
        onClose={this.handleClose}
        style={{
         ...(heightAuto?{height:'auto'}:{height: (maximum && '90vh') || (vh && `${vh}vh`) || `${height}px`}) ,
          left: `${left}px`,
          minWidth: `${minWidth}px`,
          top: `${top}px`,
          width: (maximum && '90vw') || (vw && `${vw}vw`) || `${width}px`
        }}
      >
        <Content>
          <Grip
            isActive={isMouseDown}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onMouseUp={this.handleMouseUp}
            onMouseLeave={this.handleMouseUp}
          />
          {content}
        </Content>
      </Container>
    )
  }

  private handleClose = () => {
    const { closeModal } = this.props
    closeModal()
  }

  private handleMouseDown = (e: React.MouseEvent) => {
    this.setState({
      isMouseDown: true,
      x: e.clientX,
      y: e.clientY
    })
  }

  private handleMouseMove = (e: React.MouseEvent) => {
    const { isMouseDown, left, top, x, y } = this.state
    if (!isMouseDown) {
      return
    }
    this.setState({
      left: left + (e.clientX - x),
      top: top + (e.clientY - y),
      x: e.clientX,
      y: e.clientY
    })
  }

  private handleMouseUp = () => {
    this.setState({ isMouseDown: false })
  }
}

export default Modal
