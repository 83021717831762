import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { AppState } from 'store/CombineReducers'
import * as Actions from 'store/Transactions/Actions'

import DocumentZone from 'shared/DocumentZone'
import LoadingIndicator from 'shared/LoadingIndicator'
import Modal from 'shared/Modal'
import Toast, { serverToast } from 'shared/Toast/Toast'
import { handleValidation } from 'shared/Transaction/TransactionForm/Validation'

import {
  createTransactionDocuments,
  deleteTransactionDocument,
  extractTransactionData,
  updateTransactionCommission
} from 'app/Transactions/Dashboard/TransactionMutations'
import {
  getTransactionBoards,
  getTransactionCommission,
  getTransactionDetails
} from 'app/Transactions/Dashboard/TransactionQueries'
import { isPlanAllowed } from 'shared/Billing/Queries'
import {
  createTransactionDocumentsFrom,
  createTransactionV2,
  updateExpectedCommission,
  updateTransaction
} from '../Mutations'
import {
  getDocumentsWithIds,
  getIdxPasses,
  getOffices,
  getTransactionCategories,
  getTransactionDocuments,
  getTransactionSubcategories
} from '../Queries'

import { getLoggedInUser, Routes } from 'utils'

import { Container, Section } from './Styled'

import { UserPassType } from 'app/Dashboard/MainContent/Passes/Types'
import { CommissionItemValueType, CommissionType } from 'app/Transactions/Details/Types'
import { ActionEnum } from 'shared/DocumentZone/Documents/ActionButtons/Types'
import { PDFDocumentType } from 'shared/PDFViewer/Types'
import {
  ActionEnum as FormActionEnum,
  FormErrorType,
  FormType,
  ModeEnum,
  ROLE_OPTIONS
} from 'shared/Transaction/TransactionForm/Types'
import {
  CreateTransactionParamsType,
  MutationParamsType
} from 'shared/Transaction/Types'
import { PassStatusEnum } from 'store/Pass/Types'
import { PaymentNumericEnum } from 'store/Transactions/Types'
import GenerateTransaction from '../GenerateTransaction/GenerateTransaction'
import { TransactionType, TransitionType , OptionType ,OfficeType } from './Types'
import { NotificationContent, NotificationFooter, NotificationWrapper, Submit } from '../TransactionForm/Styled'


interface StoreProps {
  storeDocs: PDFDocumentType[]
  storeTransaction: TransactionType
  officeFilter: string
  percentageAmount: number
  commission: CommissionType
  updateStoreTransaction: (transaction: TransactionType) => void
  updateStoreDocs: (docs: PDFDocumentType[]) => void
  updateCommission: any
  setCommission: (data: Partial<CommissionType>) => void
  setTotalCommission: (commission: Partial<CommissionType>) => void
  setFeeAdjustmentFlag: (data: boolean) => void
}

export interface OwnProps {
  transactionDetailView?: boolean
  docIds?: string[]
  onClose: (result: any) => void
  transactionId?: string
  mutationParams?: MutationParamsType
  officeId?: string
  hideDocs?: boolean
}

type Props = OwnProps & StoreProps & RouteComponentProps<{}, {}>

interface State {
  docs: PDFDocumentType[]
  docsPending: PDFDocumentType[]
  form: FormType
  isReady: TransitionType
  isEditing: boolean
  loading: boolean
  loadingMessage: string
  mode: ModeEnum
  transaction: TransactionType
  transition: boolean
  user: any
  jobs:{[key:string]:{
    interval:string|number|null
    extractDataCallCount:number
    extractDataStatus:string
  }}
  categories:any[]
  fileUploadLoading:boolean
  showSuccessMessage:boolean
}

class CreateTransactionModal extends React.Component<Props, State> {
  extractDataTimerRef :any=null; 

  public state = {
    docs: [] as PDFDocumentType[],
    docsPending: [] as PDFDocumentType[],
    form: {
      errors: {} as FormErrorType
    } as FormType,
    isReady: {} as TransitionType,
    isEditing: false,
    loading: false,
    loadingMessage: '',
    mode: ModeEnum.ManualAdd,
    transaction: {} as TransactionType,
    transition: true,
    user: null,
    jobs:{} as any,
    categories:[] as any[],
    fileUploadLoading:false,
    showSuccessMessage:false
  }
  public handleUpdateCaetgories=(categories:any[])=>{
    this.setState({categories:categories})
  }


  public componentWillUnmount(){
    window.clearTimeout(this.extractDataTimerRef)
  }
  public componentDidMount = async () => {
    const { docIds, transactionId } = this.props
    this.setState({ isEditing: undefined !== transactionId })
    let offices = [] as OptionType[]
    const officeData = await getOffices()
    if (officeData) {
      offices = officeData.map((item: OfficeType) => ({
        key: item._id,
        text: item.branchName,
        value: item._id
      }))
      if (offices.length === 1) {
      this.handleFormChange('office',offices[0].value)
      }
    }
    this.setState({
      loading: true,
      loadingMessage: 'Gathering user data...'
    })

    try{

    let user = await getLoggedInUser({ fromCache: true })
    if (!user) {
      user = {
        role: 'GUEST'
      }
    }

    const transaction = await this.getExistingTransaction()
    const form = this.populateForm(transaction)
    let docs = await this.getExistingDocs()
    let docsPending = [] as PDFDocumentType[]
    if (docIds) {
      this.setState({ loadingMessage: 'Transferring documents...' })
      docsPending = await getDocumentsWithIds(docIds)
      docs = [...docsPending, ...docs]
    }

    let mode = ModeEnum.ManualAdd
    if (transactionId) {
      mode = ModeEnum.ManualEdit
    }

    try {
      // checks whether active user has an idx plan active
      await isPlanAllowed('idx')
      const passes: UserPassType[] = await getIdxPasses()
      const hasActivePass =
        passes.length &&
        passes.find(
          (item: UserPassType) => item.settings.status === PassStatusEnum.Active
        )
      if (hasActivePass) {
        mode = ModeEnum.Idx
      }
    } catch (e) {
      // IDX Mode is not available
    }

    const isReady: TransitionType = {
      form: true
    }

    this.setState({
      docs,
      docsPending,
      form,
      isReady,
      loading: false,
      mode,
      user
    })

    } catch(error){
      serverToast('Something went wrong')
    }

    
  }

  handleComplete=()=>{
    this.setState({showSuccessMessage:true})
  }
  public render() {
    const { hideDocs , 
      // percentageAmount
    } = this.props

    const {
      docs,
      // form,
      isReady,
      // isEditing,
      loading,
      loadingMessage,
      mode,
      transition,
      jobs
    } = this.state
    const extractDataLoading=Object.keys(jobs).filter((id:any)=>jobs[id].extractDataStatus==='pending') 
    return (
      <Modal
        content={
          <Container>
            {(loading||extractDataLoading.length>0) && <LoadingIndicator message={loadingMessage} />}
            {this.state.showSuccessMessage? <div>
              <NotificationWrapper>
              <NotificationContent>
                The extraction of data has been started. You will be notified when the process has finished.
              </NotificationContent>

              <NotificationFooter>
              <Submit onClick={this.closeSelf} content={'Close'}/>
              </NotificationFooter>
              </NotificationWrapper>
            </div>:
             <>
             <Section
               className="emphasis-container"
               height={100}
               width={hideDocs ? 100 : 45}
             >
               {isReady.form && (
                 <GenerateTransaction 
                   mode={mode} 
                   fileLoading={this.state.fileUploadLoading} 
                   fileUrls={this.state.docs.map((el)=>el.url)}
                   user={this.state.user}
                   transactionId={this.state.transaction._id}
                   onComplete={this.handleComplete}
                   />
               )}
             </Section>
 
             {!hideDocs && (
               <Section height={100} padding={1} primary={true} width={55}>
                 <DocumentZone
                   docs={docs}
                   onAction={this.handleAction}
                   onUploadEach={this.handleFileUpload}
                 />
               </Section>
             )}
             </>
            }
          </Container>
        }
        className={transition ? 'zoomIn' : 'zoomOut'}
        closeModal={this.closeSelf}
        maximum={hideDocs || this.state.showSuccessMessage ? false : true}
        width={hideDocs ? 500 : this.state.showSuccessMessage?400:0}
        heightAuto={this.state.showSuccessMessage}
      />
    )
  }

  private handleAction = (action: string, data: any, percentage?:number) => {
    switch (action) {
      case ActionEnum.UpdateOne:
        this.handleDocumentUpdate(data)
        break

      case ActionEnum.Trash:
        this.handleDocumentDelete(data)
        break

      case FormActionEnum.Loading:
        this.setState({ loading: !!data, loadingMessage: data })
        break

      case FormActionEnum.UpdateOne:
        const { name, value } = data
        this.handleFormChange(name, value)
        break

      case FormActionEnum.Submit:
        this.handleSubmitTransaction(percentage)
        break

      default:
        return
    }
  }

  private handleSubmitTransaction = async (percentage : number|undefined) => {
    const {
      hideDocs,
      mutationParams,
      transactionId,
      updateStoreDocs,
      updateStoreTransaction,
      updateCommission,
      commission,
      setCommission,
      setTotalCommission,
      setFeeAdjustmentFlag
    } = this.props
    const { docs, docsPending, form, isEditing, user } = this.state
    const validation = handleValidation(form, isEditing )
    const audio = new Audio(
      'https://onechimp.s3-us-west-2.amazonaws.com/deduction.mp3'
    )

    if (!validation.formIsValid) {
      form.errors = validation.errors
      this.setState({ form })
      // formToast(validation.errors)
      return
    }

    this.setState({ loading: true, loadingMessage: 'Submitting...' })

    const transaction: any = { ...form }
    let feeObj
    if(percentage){
      feeObj = {...transaction.feeObj, type:PaymentNumericEnum.Percent, fee:percentage}
    } else{
      feeObj = { ...transaction.feeObj } as Omit<CommissionItemValueType, '_id'>
    }
  
    const isUpdate = await this.getExistingTransaction()
    // rounding at 2 decimal places on percentage fee
    if(isUpdate._id){
      if(feeObj.type === PaymentNumericEnum.Percent){
        transaction.fee =  Math.round(transaction.price * feeObj.fee) / 100
      }else{
        try{
          const {expectedCommission } = await getTransactionCommission(isUpdate._id)
          transaction.fee = expectedCommission
        } catch(error){
          //error in fetching expected commission
        } 
      }
    } else{
      if(feeObj.type === PaymentNumericEnum.Percent){
        updateCommission({...commission, expectedCommission:(Math.round(transaction.price * transaction.feeObj.fee) / 100)})
        transaction.fee = Math.round(transaction.price * transaction.feeObj.fee) / 100
      } else{
        updateCommission({...commission, expectedCommission:transaction.feeObj.fee})
        transaction.fee = transaction.feeObj.fee
      }
    }
   

    transaction.isActive = true
    delete transaction.errors
    delete transaction.streetAddress
    delete transaction.feeObj

    try {

      if (isUpdate._id) {
        this.setState({ loadingMessage: 'Updating transaction...' })
        const update = await updateTransaction(isUpdate._id, transaction)
        if (docsPending.length > 0) {
          await this.addDocsPending(docsPending, isUpdate._id)
        }
        if (transactionId) {
          updateStoreTransaction(update)
          if (!hideDocs) {
            updateStoreDocs(docs)
          }
        }
        this.showTransactionDetailView(update[`_id`])

        try{
          const response =  await updateTransactionCommission({expectedCommission:transaction.fee, _id: commission._id})    
          setCommission(response)
          const newData = {
            commissionDue: response.commissionDue,
            totalCredits: response.totalCredits,
            totalDebits: response.totalDebits,
            totalExpenses: response.totalExpenses,
            totalIncome: response.totalIncome,
            totalReferrals: response.totalReferrals
          }
          let transactionFeeAmount = 0, commissionFeeAmount = 0
          let capMaxAmount = 0, capCompletedAmount = 0

          const isResidential = transaction?.propertyId?.type?.name === 'Residential'
         const hasTeam = transaction?.owner?.hasTeam ?? false

          if (isResidential) {
            if (hasTeam) {
              capMaxAmount = commission.teamCap.residentialTargetCap
              capCompletedAmount = commission.teamCap.residentialCompletedCap
            } else {
              capMaxAmount = commission.transaction.owner.userCap.residentialTargetCap
              capCompletedAmount = commission.transaction.owner.userCap.residentialCompletedCap
            }
          } else {
            if (hasTeam) {
              capMaxAmount = commission.teamCap.commercialTargetCap
              capCompletedAmount = commission.teamCap.commercialCompletedCap
            } else {
              capMaxAmount = commission.transaction.owner.userCap.commercialTargetCap
              capCompletedAmount = commission.transaction.owner.userCap.commercialCompletedCap
            }
          }

          if (response.fees) {
            const transactionFee = response.fees.find((el: any) => el.name === 'Transaction Fee')
            if (transactionFee) {
              transactionFeeAmount = transactionFee.amount
            }
            const commissionFee = response.fees.find((el: any) => el.name === 'Commission Fee (default)')
            if (commissionFee) {
              commissionFeeAmount = commissionFee.amount
            }
          }
      
          const exceedsCap = (transactionFeeAmount + commissionFeeAmount) > (capMaxAmount - capCompletedAmount)
          setFeeAdjustmentFlag(exceedsCap)

          setTotalCommission(newData)
    
        }catch(error){
          //error in updating transaction Commission
        } finally{
          this.setState({ loading: false })
        }
        
        return this.closeSelf(update)
      }

      this.setState({ loadingMessage: 'Creating transaction...' })
      const boards = await getTransactionBoards({})
      const newBoard = boards.find(
        (board: any) => board.name.toLowerCase() === 'new'
      )

      const params: CreateTransactionParamsType = {
        addBoardId: newBoard._id,
        edit: false,
        index: '',
        show: false,
        transaction,
        user,
        ...mutationParams
      }

      const create = await createTransactionV2(params)
      if (create) {
        audio.play()
        this.addExpectedCommission(transaction.fee, create.payment._id)
        this.showTransactionDetailView(create[`_id`])
      }
      if (create && docsPending.length > 0) {
        await this.addDocsPending(docsPending, create._id)
      }
      if (transactionId) {
        updateStoreTransaction(create)
        updateStoreDocs(docs)
      }
      return this.closeSelf(create)
    } catch (error) {
      serverToast(error)
    } finally{
      this.setState({ loading: false })
    }
  }

  private showTransactionDetailView = (transactionID: string) => {
    const { transactionDetailView } = this.props
    if (transactionDetailView) {
      this.props.history.push({
        pathname: `${Routes.transactions.root}/${transactionID}${
          Routes.transactions.documents.path
        }`
      })
    }
  }

  private handleFormChange = (name: string, value: any) => {
    //name:"feeObj"
    // value:{type:'PERCENTAGE',fee:3}
    const { form } = this.state
    form[name] = value
    form.errors = {} as FormErrorType
    this.setState({ form })
  }

  private handleDocumentUpdate = async (doc: PDFDocumentType) => {
    const docs = await this.getExistingDocs()

    let update = [...docs]
    const index = update.findIndex((item: PDFDocumentType) => {
      return item._id === doc._id
    })

    if (index < 0) {
      update = [doc, ...update]
    } else {
      update[index] = doc
    }

    this.setState({ docs: update })
  }

  private handleDocumentDelete = async (doc: PDFDocumentType) => {
    const docs = await this.getExistingDocs()

    const update: any = [...docs]
    const index = update.findIndex((item: any) => item._id === doc._id)
    try {
      update.splice(index, 1)
      await deleteTransactionDocument(doc._id)
      this.setState({ docs: update })
      Toast({ message: 'Your document has been deleted', type: 'success' })
    } catch (error) {
      Toast({
        message: 'An error occurred while deleting the document',
        type: 'error'
      })
    }
  }

  private handleFileUpload = async (file: File) => {
    this.setState({fileUploadLoading:true})
    const docs = await this.getExistingDocs()
    let transaction = await this.getExistingTransaction()
    if (!transaction._id) {
      transaction = await this.createPlaceholderTransaction()
      if (!transaction._id) {
        return
      }
    }
    try {
      const doc = await createTransactionDocuments([file], transaction._id)
      const update = [doc[0], ...docs]
      this.setState({fileUploadLoading:false})
      this.setState({ docs: update })
    } catch (error) {
      serverToast(error)
      this.setState({fileUploadLoading:false})
    }
  }
  //TODO: remove this function
  private async setExtractData(jobId:string){
    console.log(this.state.jobs,'-----this.state.jobs')
    const job=this.state.jobs?.[jobId]
    if(job&&job.extractDataCallCount>10){
      window.clearInterval(job.interval)
      return
    }
    const extractData=await extractTransactionData(jobId)
    console.log('extractTransactionData--------------',extractData)
  if(extractData?.extractTransactionData?.status==='pending'){
    if(job&&job.extractDataStatus==='pending'){
        this.setState((prev)=>{return {...prev,jobs:{
          ...prev.jobs,
          [jobId]:{
              ...job,
              extractDataCallCount:prev.jobs?.[jobId].extractDataCallCount+1,
              extractDataStatus:'pending'
            }
          }
        }}
      )
    }else{
      const interval= window.setInterval(()=>{
        this.setExtractData(jobId)
      },10000)
        this.setState((prev)=>{return {
          ...prev,
          jobs:{
          ...prev.jobs,
          [jobId]:{
              interval:interval,
              extractDataCallCount:0,
              extractDataStatus:'pending'
            }
          }
        }}
      )
    } 
  }else{
    window.clearInterval(job.interval)
      this.setState((prev)=>{return {...prev,jobs:{
        ...prev.jobs,
         [jobId]:{
             interval:null,
             extractDataCallCount:0,
             extractDataStatus:'complete'
           }
         }
       }}
    )
    if(extractData){
      const formData=extractData?.extractTransactionData?.data
      const categoryVal=this.state.categories.find((el)=>(el?.name||'').toLowerCase()===formData?.category)
      const subCategoryVal=categoryVal?.propertySubCategories?.find((el:any)=>(el?.name||'').toLowerCase()===formData?.sub_category)
      const transactionTypeData=ROLE_OPTIONS.find((el)=>el?.value?.toLowerCase()===formData?.transaction_type?.toLowerCase())
      this.setState({
        form:{
          ...this.state.form,
          streetAddress:formData?.property?.address??this.state.form.streetAddress, 
          city:formData?.property?.city??this.state.form.city,
          zipCode:formData?.property?.zip_code||formData?.property?.zip||this.state.form.zipCode,
          transactionRole:transactionTypeData?.value??this.state.form.transactionRole,
          price:formData?.price??this.state.form.price,
          state:formData?.property?.state || this.state.form.state,
          type:categoryVal?._id || this.state.form.type,
          subType:subCategoryVal?._id || this.state.form.subType,
          feeObj:{
            ...this.state.form.feeObj,
            type:(formData?.transaction_type?.toLowerCase() == 'buyer'?
              formData?.compensation?.buyer_brokerage_percentage || '2.5%'
              :formData?.compensation?.listing_firm_percentage || '2.5%')?.includes('%')?PaymentNumericEnum.Percent:PaymentNumericEnum.Fixed,
            fee:(formData?.transaction_type?.toLowerCase() == 'buyer'?
              formData?.compensation?.buyer_brokerage_percentage?.replace(/%|\$/g,'') || this.state.form.feeObj?.fee|| '2.5'
              :formData?.compensation?.listing_firm_percentage?.replace(/%|\$/g,'') || this.state.form.feeObj?.fee|| '2.5')}}})
    }
  }
  }
//

  private getExistingTransaction = async () => {
    const { storeTransaction, transactionId } = this.props
    const { transaction } = this.state

    if (transaction._id) {
      return transaction
    }

    const storeMatchesSupply =
      transactionId && transactionId === storeTransaction._id
    if (storeMatchesSupply) {
      this.setState({ transaction: storeTransaction })
      return storeTransaction
    }

    if (transactionId) {
      try {
        this.setState({ loadingMessage: 'Gathering transaction data...' })
        const existing = await getTransactionDetails(transactionId)
        this.setState({ transaction: existing[0] })
        return existing[0]
      } catch (error) {
        serverToast(error)
        return {} as TransactionType
      }
    }

    return {} as TransactionType
  }

  private getExistingDocs = async () => {
    const { hideDocs, storeDocs, transactionId } = this.props
    const { docs } = this.state

    if (hideDocs) {
      return [] as PDFDocumentType[]
    }

    if (docs.length > 0) {
      return docs
    }

    const storeMatchesSupply =
      transactionId &&
      storeDocs.length > 0 &&
      storeDocs[0].transaction._id === transactionId
    if (storeMatchesSupply) {
      this.setState({ docs: storeDocs })
      return storeDocs
    }

    if (transactionId) {
      try {
        this.setState({ loadingMessage: 'Gathering transaction documents...' })
        const existing = await getTransactionDocuments(transactionId)
        this.setState({ docs: existing })
      } catch (error) {
        serverToast(error)
        return [] as PDFDocumentType[]
      }
    }

    return [] as PDFDocumentType[]
  }

  private populateForm = (transaction: TransactionType) => {
    const { officeId } = this.props
    const { form } = this.state

    if (!transaction._id) {
      return {
        ...form,
        office: officeId || form.office
      }
    }

    const {
      officeTransactionBoard,
      propertyId: {
        address: { city, state, streetName, streetNumber, zipCode },
        bathrooms,
        bedrooms,
        description,
        mlsId,
        price,
        squareFt,
        subType,
        type
      },
      transactionRole
    } = transaction

    const populateForm: FormType = {
      bathrooms,
      bedrooms,
      city,
      description,
      errors: {} as FormErrorType,
      mlsId,
      office:
        (officeTransactionBoard &&
          officeTransactionBoard.office &&
          officeTransactionBoard.office._id) ||
        form.office,
      price,
      squareFt,
      state,
      streetAddress: `${streetNumber} ${streetName}`,
      streetName,
      streetNumber,
      subType: subType._id,
      transactionRole,
      type: type._id,
      zipCode
    }

    return populateForm
  }

  private addDocsPending = async (
    docsPending: PDFDocumentType[],
    transactionId: string
  ) => {
    const docIds = docsPending.map((item: PDFDocumentType) => item._id)
    if (docIds.length === 0) {
      return
    }
    try {
      await createTransactionDocumentsFrom(docIds, transactionId)
    } catch (error) {
      serverToast(error)
    }
  }

  private addExpectedCommission = async (fee: number, commissionId: string) => {
    try {
      await updateExpectedCommission(fee, commissionId)
    } catch (error) {
      serverToast(error)
    }
  }

  private createPlaceholderTransaction = async () => {
    const { mutationParams } = this.props
    const { form, isEditing } = this.state

    let user: any = this.state.user
    if (!user) {
      user = await getLoggedInUser({ fromCache: true })
      if (!user) {
        user = {
          role: 'GUEST'
        }
      }
    }
    this.setState({ user })

    const transaction: any = { ...form }
    delete transaction.errors
    delete transaction.streetAddress

    const validation = handleValidation(form, isEditing)
    if (!validation.formIsValid) {
      Object.keys(validation.errors).forEach(key => {
        transaction[key] = 'PENDING'
      })
    }

    if (transaction.price === 'PENDING') {
      transaction.price = 0
    }

    if (transaction.transactionRole === 'PENDING') {
      transaction.transactionRole = 'Both'
    }

    if (transaction.type === 'PENDING') {
      try {
        const categories = await getTransactionCategories()
        transaction.type = categories[0]._id
      } catch {
        return null
      }
    }

    if (transaction.subType === 'PENDING') {
      try {
        const subcategories = await getTransactionSubcategories(
          transaction.type
        )
        transaction.subType = subcategories[0]._id
      } catch {
        return null
      }
    }

    delete transaction.feeObj

    transaction.isActive = false

    const boards = await getTransactionBoards({})
    const newBoard = boards.find(
      (board: any) => board.name.toLowerCase() === 'new'
    )

    const params: CreateTransactionParamsType = {
      addBoardId: newBoard._id,
      edit: false,
      index: '',
      show: false,
      transaction,
      user,
      ...mutationParams
    }

    try {
      const placeholder = await createTransactionV2(params)
      this.setState({ transaction: placeholder })
      return placeholder
    } catch (error) {
      serverToast(error)
      return {} as TransactionType
    }
  }

  private closeSelf = (result: any = null) => {
    const { onClose } = this.props
    this.setState({ transition: false })
    window.setTimeout(() => {
      this.setState({ transition: true })
      onClose(result)
    }, 300)
  }
}

const mapStateToProps = (state: AppState) => ({
  officeFilter: state.transactions.officeFilter,
  storeDocs: state.transactions.documents,
  storeTransaction: state.transactions.transactionDetail,
  percentageAmount: state.transactions.percentageAmount,
  commission: state.transactions.commission
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      updateStoreDocs: Actions.getTransactionDocuments,
      updateStoreTransaction: Actions.getTransactionDetail,
      updateCommission: Actions.updateCommission,
      setCommission: Actions.getCommission,
      setTotalCommission: Actions.totalCommission,
      setFeeAdjustmentFlag: Actions.setFeeAdjustmentFlag,
    }
  )(CreateTransactionModal)
)
